import SecondaryHeader from "../components/SecondaryHeader";
import ilmiyaIcon from "../images/icons/ilmiya-icon.svg";
import iPad from "../images/iPad.png";
import LegalTopbar from "../components/LegalTopbar";
import { Link } from "react-router-dom";
import LegalSidebar from "../components/LegalDetailSidebar";
import SecondaryFooter from "../components/SecondaryFooter";
import Accordion from "react-bootstrap/Accordion";
import { HiOutlineKey } from "react-icons/hi2";
import termspagetopimg from "../images/terms-page-top-image.png"
import legalIcon from "../images/icons/legal-icon.svg"
import ilmiyalegalimg from "../images/ilmiyalegalimg.svg"


export default function Termsofprivacy() {
  return (
    <div className="legal-details-page-wrappper">
            <SecondaryHeader />
      <div className="page-breadcrumb">
        <strong>
          <Link className='breadcrumb-icon-text' to={"/legal"}> <img src={legalIcon} alt='legal-icon'/><strong> Legal Center</strong></Link>/ Terms Of Service
        </strong>
      </div>
      <div className="page-content-wrapper">
        {/* <LegalSidebar /> */}
        <div className="page-content">
          <div className="container">
            <div className="page-content-section page-protect-section">
                        <div className="top-image-wrapper d-flex justify-content-center">
                        <img src={ilmiyalegalimg} alt="ilmiya legal center img" className="ilmiya-legal-center-img" />
                        </div>
              <div className="page-content-container">
                <div className="content-title">
                  <h1>Terms of Service</h1>
                </div>

                <div className="privacy-content-section">
                  <div className="privacy-content-accordion">
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Table of contents</Accordion.Header>
                        <Accordion.Body style={{borderTop:"1px solid #ecedef"}}>
                          <a href="#intro">
                            <h2 className="small" >Introduction</h2>
                          </a>
                          <ul className="disc-style-ul">
                            <li>
                              <a href="#terms">1. General Account Terms</a>
                            </li>
                           <li>
                              <a href="#communication">2. Communications</a>
                            </li>
                            <li>
                              <a href="#purchase">3. Purchases</a>
                            </li>
                            <li>
                              <a href="#promotion">
                                4. Contests, Sweepstakes and Promotions
                              </a>
                            </li>
                            <li>
                              <a href="#subscription">5. Subscriptions</a>
                            </li>
                            <li>
                              <a href="#trial">6. Free Trial</a>
                            </li>
                            <li>
                              <a href="#fee">7. Fee Changes</a>
                            </li>
                            <li>
                              <a href="#refund">8. Refunds & Cancellation</a>
                            </li>
                            <li>
                              <a href="#content">9. Content</a>
                            </li>
                            <li>
                              <a href="#prohibit">10. Prohibited Uses</a>
                            </li>
                           <li>
                              <a href="#analytic">11. Analytics</a>
                            </li>
                            <li>
                              <a href="#require">12. Age Requirements</a>
                            </li>
                            <li>
                              <a href="#account">13. Accounts</a>
                            </li>
                            <li>
                            <a href="#intellectual">14. Intellectual Property</a>
                            
                            </li>
                            <li>
                              <a href="#policy">15. Copyright Policy</a>
                            </li>
                           <li>
                              <a href="#notice">
                              16. DMCA Notice and Procedure for Copyright Infringement Clai
                              </a>
                            </li>
                           <li>
                              <a href="#error">
                              17. Error Reporting and Feedback
                              </a>
                            </li>
                            <li>
                              <a href="#links-to-site">
                              18. Links To Other Web Sites
                              </a>
                            </li>
                            <li>
                              <a href="#warranty">
                              19. Disclaimer Of Warranty
                              </a>
                            </li>
                            <li>
                              <a href="#liability">
                              20. Limitation Of Liability
                              </a>
                            </li>
                            <li>
                              <a href="#termination">
                              21. Termination
                              </a>
                            </li>
                            <li>
                              <a href="#govern-law">
                              22. Governing Law
                              </a>
                            </li>
                            <li>
                              <a href="#service">
                              23. Changes To Service
                              </a>
                            </li>
                           <li>
                              <a href="#amendment">
                              24. Amendments To Terms
                              </a>
                            </li>
                            <li>
                              <a href="#waiver">
                              25. Waiver And Severability
                              </a>
                            </li>
                            <li>
                              <a href="#acknowledgement">
                              26. Acknowledgement
                              </a>
                            </li>
                            <li>
                              <a href="#contact-us">
                              27. Contact Us
                              </a>
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>

                   <div className="content-wrapper">
                <div className="description mt-0">
                  <h2 id="intro">Introduction</h2>
                  <p className="mt-2">
                    Welcome to Ilmiya (“Company”, “we”, “our”, “us”)! As you
                    have just clicked our Terms of Service, please pause, grab a
                    cup of coffee and carefully read the following pages. It
                    will take you approximately 20 minutes.
                  </p>
                  <p className="mt-2">
                    These Terms of Service (“Terms”, “Terms of Service”) govern
                    your use of our web pages located at <a href="#">
                    <span>ilmiya.com </span>
                      </a> {" "}
                     and our mobile application Ilmiya (together or individually
                    “Service”) operated by Ilmiya
                  </p>
                  <p className="mt-2">
                    Our Privacy Policy also governs your use of our Service and
                    explains how we collect, safeguard and disclose information
                    that results from your use of our web pages. Please read it
                    here <a href="#"> <span className="bg-blue"><HiOutlineKey />
                    Privacy Policy</span></a>
                  </p>
                  <p className="mt-2">
                    Your agreement with us includes these Terms and our Privacy
                    Policy (“Agreements”). You acknowledge that you have read
                    and understood Agreements, and agree to be bound of them.
                  </p>
                  <p className="mt-2">
                    If you are entering into this Agreement on behalf of a
                    company or other legal entity, you represent that you have
                    the authority to bind such entity, its affiliates, and all
                    users who access the Service through your account to this
                    Agreement, in which case the terms “you” or “your” shall
                    refer to such entity, its affiliates, and users associated
                    with it. If you do not have such authority, or if you do not
                    agree with these terms and conditions, you must not accept
                    this Agreement and may not use the Service.
                  </p>
                  <p className="mt-2">
                    PLEASE READ THIS AGREEMENT CAREFULLY BEFORE ACCESSING OUR
                    SITE OR USING THE SERVICE. BY ACCESSING OR USING ANY PART OF
                    THE SITE, YOU AGREE TO BECOME BOUND BY THIS AGREEMENT. THESE
                    TERMS INCLUDE A BINDING ARBITRATION CLAUSE AND A CLASS
                    ACTION WAIVER.
                  </p>
                  <p className="mt-2">
                    If you do not agree with (or cannot comply with) Agreements,
                    then you may not use the Service, but please let us know by
                    emailing at <a href="#"> <span>support@ilmiya.com </span></a> so we can try
                    to find a solution. These Terms apply to all visitors, users
                    and others who wish to access or use Service.
                  </p>
                  <p className="mt-2">Thank you for being responsible.</p>
                </div>
                <div className="description high-space">
                  <h2 id="terms">1. General Account Terms</h2>
                  <p className="mt-2">
                    In order to use our Service, you must be a human being.
                    Accounts registered by “bots” or other automated methods are
                    not permitted.
                  </p>
                  <p className="mt-2">
                    Each account may be only used by one person, and you may
                    only have one account. If it is discovered that you have
                    registered multiple accounts, are sharing accounts with
                    another individual, or are otherwise accessing the Site
                    through an account that is not your own, all accounts
                    affected by such use shall be terminated.
                  </p>
                  <p className="mt-2">
                    You are responsible for maintaining the security of your
                    account and password. Company cannot and will not be liable
                    for any loss or damage from your failure to comply with this
                    security obligation. You are fully responsible for all
                    activities that occur under the account and any other
                    actions taken in connection with the account. You must
                    immediately notify Company of any unauthorized uses of your
                    account, or any other breaches of security. We will not be
                    liable for any acts or omissions by you, including any
                    damages of any kind incurred as a result of such acts or
                    omissions.
                  </p>
                  <p className="mt-2">
                    You may not use the Service for any illegal or unauthorized
                    purpose. You must not, in the use of the Service, violate
                    any laws in your jurisdiction (including but not limited to
                    copyright or trademark laws). No matter what else is said in
                    these Terms or anywhere else within the Services, you
                    expressly acknowledge that you have no right, title, or
                    interest to or in any account you create through our
                    Services, and your account is not your property.
                  </p>
                  <p className="mt-2">
                    Company may remove Content that violates the Agreement at
                    its sole discretion. Removing Content, or terminating an
                    account are not actions we take lightly, and we may take a
                    while to investigate and determine if such actions are
                    warranted. It is at our sole discretion whether to discuss
                    the reasons for taking action against any Content or
                    account.
                  </p>
                  <p className="mt-2">
                    We reserve the right to withdraw or amend our Site, and any
                    service or material we provide on the Site, in our sole
                    discretion without notice. We will not be liable if for any
                    reason all or any part of the Site is unavailable at any
                    time or for any period. From time to time, we may restrict
                    access to some parts of the Site, or the entire Site, to
                    users, including registered users.
                  </p>
                  <p className="mt-2">You are responsible for both:</p>
                  <ul>
                    <li>
                      <p className="mt-2">
                        Making all arrangements necessary for you to have access
                        to the Site.
                      </p>
                    </li>
                    <li>
                      <p className="mt-2">
                        Ensuring that all persons who access the Site through
                        your internet connection are aware of these Terms of Use
                        and comply with them.
                      </p>
                    </li>
                  </ul>
                  <p className="mt-2">
                    To access the Site or some of the resources it offers, you
                    may be asked to provide certain registration details or
                    other information. It is a condition of your use of the Site
                    that all the information you provide on the Site is correct,
                    current, and complete. You agree that all information you
                    provide to register with this Site or otherwise, including,
                    but not limited to, through the use of any interactive
                    features on the Site, is governed by our Privacy Policy, and
                    you consent to all actions we take with respect to your
                    information consistent with our <a href="#"> <span className="bg-blue"><HiOutlineKey />
                    Privacy Policy</span></a>
                  </p>
                </div>
                <div className="description">
                  <h2 id="communication">2. Communications</h2>
                  <p className="mt-2">
                    By creating an Account on our Service, you agree to
                    subscribe to newsletters, marketing or promotional materials
                    and other information we may send. However, you may opt out
                    of receiving any, or all, of these communications from us by
                    following the unsubscribe link or by emailing at.
                  </p>
                </div>

                <div className="description">
                  <h2 id="purchase">3. Purchases</h2>
                  <p className="mt-2">
                    If you wish to purchase any product or service made
                    available through Service (“Purchase”), you may be asked to
                    supply certain information relevant to your Purchase
                    including, without limitation, your credit card number, the
                    expiration date of your credit card, your billing address,
                    and your shipping information.
                  </p>
                  <p className="mt-2">
                    You represent and warrant that: (i) you have the legal right
                    to use any credit card(s) or other payment method(s) in
                    connection with any Purchase; and that (ii) the information
                    you supply to us is true, correct and complete.
                  </p>
                  <p className="mt-2">
                    We may employ the use of third party services for the
                    purpose of facilitating payment and the completion of
                    Purchases. By submitting your information, you grant us the
                    right to provide the information to these third parties
                    subject to our  <a href="#"> <span className="bg-blue"> <HiOutlineKey />
                    Privacy Policy</span></a> .
                  </p>
                  <p className="mt-2">
                    We reserve the right to refuse or cancel your order at any
                    time for reasons including but not limited to: product or
                    service availability, errors in the description or price of
                    the product or service, error in your order or other
                    reasons.
                  </p>
                  <p className="mt-2">
                    We reserve the right to refuse or cancel your order if fraud
                    or an unauthorized or illegal transaction is suspected.
                  </p>
                </div>

                <div className="description">
                  <h2 id="promotion">4. Contests, Sweepstakes and Promotions</h2>
                  <p className="mt-2">
                    Any contests, sweepstakes or other promotions (collectively,
                    “Promotions”) made available through Service may be governed
                    by rules that are separate from these Terms of Service. If
                    you participate in any Promotions, please review the
                    applicable rules as well as our
                    <a href="#"> <span className="bg-blue"> <HiOutlineKey />
                    Privacy Policy</span></a> . If the rules for a Promotion
                    conflict with these Terms of Service, Promotion rules will
                    apply.
                  </p>
                </div>

                <div className="description">
                  <h2 id="subscription">5. Subscriptions</h2>
                  <p className="mt-2">
                    Some parts of Service are billed on a subscription basis
                    (“Subscription(s)”). You will be billed in advance on a
                    recurring and periodic basis (“Billing Cycle”). Billing
                    cycles are set either on a monthly or annual basis,
                    depending on the type of subscription plan you select when
                    purchasing a Subscription.
                  </p>
                  <p className="mt-2">
                    At the end of each Billing Cycle, your Subscription will
                    automatically renew under the exact same conditions unless
                    you cancel it or Ilmiya cancels it. You may cancel your
                    Subscription renewal either through your online account
                    management page or by contacting Ilmiya customer support
                    team.
                  </p>
                  <p className="mt-2">
                    A valid payment method, including credit card or PayPal, is
                    required to process the payment for your subscription. You
                    shall provide Ilmiya with accurate and complete billing
                    information including full name, address, state, zip code,
                    telephone number, and a valid payment method information. By
                    submitting such payment information, you automatically
                    authorize Ilmiya to charge all Subscription fees incurred
                    through your account to any such payment instruments.
                  </p>
                  <p className="mt-2">
                    Should automatic billing fail to occur for any reason,
                    Ilmiya will issue an electronic invoice indicating that you
                    must proceed manually, within a certain deadline date, with
                    the full payment corresponding to the billing period as
                    indicated on the invoice.
                  </p>
                </div>

                <div className="description">
                  <h2 id="trial">6. Free Trial</h2>
                  <p className="mt-2">
                    Ilmiya may, at its sole discretion, offer a Subscription
                    with a free trial for a limited period of time (“Free
                    Trial”).
                  </p>
                  <p className="mt-2">
                    You may be required to enter your billing information in
                    order to sign up for Free Trial.
                  </p>
                  <p className="mt-2">
                    If you do enter your billing information when signing up for
                    Free Trial, you will not be charged by Ilmiya until Free
                    Trial has expired. On the last day of Free Trial period,
                    unless you cancelled your Subscription, you will be
                    automatically charged the applicable Subscription fees for
                    the type of Subscription you have selected.
                  </p>
                  <p className="mt-2">
                    At any time and without notice, Ilmiya reserves the right to
                    (i) modify Terms of Service of Free Trial offer, or (ii)
                    cancel such Free Trial offer.
                  </p>
                </div>

                <div className="description">
                  <h2 id="fee">7. Fee Changes</h2>
                  <p className="mt-2">
                    Ilmiya, in its sole discretion and at any time, may modify
                    Subscription fees for the Subscriptions. Any Subscription
                    fee change will become effective at the end of the
                    then-current Billing Cycle.
                  </p>
                  <p className="mt-2">
                    Ilmiya will provide you with a reasonable prior notice of
                    any change in Subscription fees to give you an opportunity
                    to terminate your Subscription before such change becomes
                    effective.
                  </p>
                  <p className="mt-2">
                    Your continued use of Service after Subscription fee change
                    comes into effect constitutes your agreement to pay the
                    modified Subscription fee amount.
                  </p>
                </div>

                <div className="description">
                  <h2 id="refund">8. Refunds & Cancellation</h2>
                  <p className="mt-2">
                    Except when required by law, paid Subscription fees are
                    non-refundable.
                  </p>
                  <p className="mt-2">
                    One month (30 days) advance written notice is due on the 1st
                    of the current month to discontinue Ilmiya services. Failure
                    to give notice by the 1st of the current month will result
                    in enrollment for the following month and termination
                    occurring the month after. No exceptions. Paid tuition is
                    non-refundable regardless of duration.
                  </p>
                  <p className="mt-2">
                    {" "}
                    Cancellation submitted days 1st - 5th of the month:
                    Termination/changes occur next month.
                  </p>
                  <p className="mt-2">
                    Cancellation submitted days 6th - 31st of the month:
                    Enrolled next month, termination/changes occur the month
                    after.
                  </p>
                  <p className="mt-2">
                    Please provide Ilmiya an advance notice of absences,
                    students almost invariably lose momentum and regress with
                    periods of a week or more of not doing daily practice. It is
                    advisable to take work with you on vacations and have them
                    do it daily. Ilmiya allows one session absences per calendar
                    year before the student is dropped from enrollment. Absences
                    over three sessions in a calendar year require re-enrollment
                    with the associated costs.
                  </p>
                </div>
                <div className="description">
                  <h2 id="content">9. Content</h2>
                  <p className="mt-2">
                    Our Service allows you to post, link, store, share and
                    otherwise make available certain information, text,
                    graphics, videos, or other material (“Content”). You are
                    responsible for Content that you post on or through Service,
                    including its legality, reliability, and appropriateness.
                  </p>
                  <p className="mt-2">
                    By posting Content on or through Service, You represent and
                    warrant that: (i) Content is yours (you own it) and/or you
                    have the right to use it and the right to grant us the
                    rights and license as provided in these Terms, and (ii) that
                    the posting of your Content on or through Service does not
                    violate the privacy rights, publicity rights, copyrights,
                    contract rights or any other rights of any person or entity.
                    We reserve the right to terminate the account of anyone
                    found to be infringing on a copyright.
                  </p>
                  <p className="mt-2">
                    You retain any and all of your rights to any Content you
                    submit, post or display on or through Service and you are
                    responsible for protecting those rights. We take no
                    responsibility and assume no liability for Content you or
                    any third party posts on or through Service. However, by
                    posting Content using Service you grant us the right and
                    license to use, modify, publicly perform, publicly display,
                    reproduce, and distribute such Content on and through
                    Service. You agree that this license includes the right for
                    us to make your Content available to other users of Service,
                    who may also use your Content subject to these Terms.
                  </p>
                  <p className="mt-2">
                    Ilmiya has the right but not the obligation to monitor and
                    edit all Content provided by users.
                  </p>
                  <p className="mt-2">
                    In addition, Content found on or through this Service are
                    the property of Ilmiya or used with permission. You may not
                    distribute, modify, transmit, reuse, download, repost, copy,
                    or use said Content, whether in whole or in part, for
                    commercial purposes or for personal gain, without express
                    advance written permission from us.
                  </p>
                </div>
                <div className="description">
                  <h2 id="prohibit">10. Prohibited Uses</h2>
                  <p className="mt-2">
                    You may use Service only for lawful purposes and in
                    accordance with Terms. You agree not to use Service:
                  </p>
                  <p className="mt-2">
                    In any way that violates any applicable national or
                    international law or regulation.
                  </p>
                  <p className="mt-2">
                    For the purpose of exploiting, harming, or attempting to
                    exploit or harm minors in any way by exposing them to
                    inappropriate content or otherwise.
                  </p>
                  <p className="mt-2">
                    To transmit, or procure the sending of, any advertising or
                    promotional material, including any “junk mail”, “chain
                    letter,” “spam,” or any other similar solicitation.
                  </p>
                  <p className="mt-2">
                    To impersonate or attempt to impersonate Company, a Company
                    employee, another user, or any other person or entity.
                  </p>
                  <p className="mt-2">
                    In any way that infringes upon the rights of others, or in
                    any way is illegal, threatening, fraudulent, or harmful, or
                    in connection with any unlawful, illegal, fraudulent, or
                    harmful purpose or activity.
                  </p>
                  <p className="mt-2">
                    To engage in any other conduct that restricts or inhibits
                    anyone’s use or enjoyment of Service, or which, as
                    determined by us, may harm or offend Company or users of
                    Service or expose them to liability.
                  </p>
                  <p className="mt-2">
                    To use the Services to enable any person (including you) to
                    benefit from any activities Stripe has identified as a
                    restricted business or activity (collectively, “Restricted
                    Businesses”). Restricted Businesses include use of the
                    Services in or for the benefit of a country, organization,
                    entity, or person embargoed or blocked by any government,
                    including those on sanctions lists identified by the United
                    States Office of Foreign Asset Control (OFAC).
                  </p>
                  <p className="mt-2">
                    Please review the list of Restricted Businesses thoroughly
                    before registering for and opening a Ilmiya Account. If you
                    are uncertain whether a category of business or activity is
                    restricted or have questions about how these restrictions
                    apply to you, please contact us. We may add to or update the
                    Restricted Business List at any time.
                  </p>
                  <p className="mt-2">Additionally, you agree not to:</p>
                  <ul>
                    <li>
                      <p className="mt-2">
                        Use Service in any manner that could disable,
                        overburden, damage, or impair Service or interfere with
                        any other party’s use of Service, including their
                        ability to engage in real time activities through
                        Service.
                      </p>
                    </li>
                  </ul>

                  <ul>
                    <li>
                      <p className="mt-2">
                        Use any robot, spider, or other automatic device,
                        process, or means to access Service for any purpose,
                        including monitoring or copying any of the material on
                        Service.
                      </p>
                    </li>
                  </ul>

                  <ul>
                    <li>
                      <p className="mt-2">
                        Use any manual process to monitor or copy any of the
                        material on Service or for any other unauthorized
                        purpose without our prior written consent.
                      </p>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <p className="mt-2">
                        Use any device, software, or routine that interferes
                        with the proper working of Service.
                      </p>
                    </li>
                  </ul>

                  <ul>
                    <li>
                      <p className="mt-2">
                        Introduce any viruses, trojan horses, worms, logic
                        bombs, or other material which is malicious or
                        technologically harmful.
                      </p>
                    </li>
                  </ul>

                  <ul>
                    <li>
                      <p className="mt-2">
                        Attempt to gain unauthorized access to, interfere with,
                        damage, or disrupt any parts of Service, the server on
                        which Service is stored, or any server, computer, or
                        database connected to Service.
                      </p>
                    </li>
                  </ul>

                  <ul>
                    <li>
                      <p className="mt-2">
                        Attack Service via a denial-of-service attack or a
                        distributed denial-of-service attack.
                      </p>
                    </li>
                  </ul>

                  <ul>
                    <li>
                      <p className="mt-2">
                        Take any action that may damage or falsify Company
                        rating.
                      </p>
                    </li>
                  </ul>

                  <ul>
                    <li>
                      <p className="mt-2">
                        Otherwise attempt to interfere with the proper working
                        of Service.
                      </p>
                    </li>
                  </ul>
                </div>

                <div className="description">
                  <h2 id="analytic">11. Analytics</h2>
                  <p className="mt-2">
                    We may use third-party Service Providers to monitor and
                    analyze the use of our Service. For more details please
                    refer to our privacy policy at  <a href="#"> <span className="bg-blue"> <HiOutlineKey />
                    Privacy Policy</span></a>
                  </p>
                </div>

                <div className="description">
                  <h2 id="require">12. Age Requirements</h2>
                  <p className="mt-2">
                    By accessing our services, you confirm that you’re at least
                    13 years old and meet the minimum age of digital consent in
                    your country. If you are old enough to access our services
                    in your country, but not old enough to have authority to
                    consent to our terms, your parent or guardian must agree to
                    our terms on your behalf. Please ask your parent or guardian
                    to read these terms with you. If you’re a parent or legal
                    guardian, and you allow your teenager to use the services,
                    then these terms also apply to you and you’re responsible
                    for your teenager’s activity on the services.
                  </p>
                  <p className="mt-2">
                    Ilmiya understands the importance of safeguarding the
                    personal data of children, which we consider to be an
                    individual under the age of 13 or the equivalent age as
                    specified by law in your jurisdiction. That is why Ilmiya
                    has implemented additional processes and protections to help
                    keep children's personal data safe.
                  </p>
                  <p className="mt-2">
                    If you are under the age of 13, you may not create or
                    register an account for the Services without consent and
                    approval from your legal parent or guardian, or from your
                    educational institution. If you are under the age of 18, you
                    represent that your parent or legal guardian has reviewed
                    and agreed to the Terms on your behalf. A Child User will
                    only be permitted to use the Services for so long as Ilmiya
                    reasonably believes that such access has been consented to
                    by the Child User’s parent or guardian, or by their
                    educational institution. To access certain Ilmiya services,
                    a child must have an Ilmiya account. An Ilmiya account may
                    be created by the parent or, in the case of a managed by the
                    child's institution. If we learn that a child's personal
                    data was collected without appropriate authorization, it
                    will be deleted as soon as possible.
                  </p>
                </div>

                <div className="description">
                  <h2 id="account">13. Accounts</h2>
                  <p className="mt-2">
                    When you create an account with us, you guarantee that you
                    are above the age of 13, and that the information you
                    provide us is accurate, complete, and current at all times.
                    Inaccurate, incomplete, or obsolete information may result
                    in the immediate termination of your account on Service.
                  </p>
                  <p className="mt-2">
                    You are responsible for maintaining the confidentiality of
                    your account and password, including but not limited to the
                    restriction of access to your computer and/or account. You
                    agree to accept responsibility for any and all activities or
                    actions that occur under your account and/or password,
                    whether your password is with our Service or a third-party
                    service. You must notify us immediately upon becoming aware
                    of any breach of security or unauthorized use of your
                    account.
                  </p>
                  <p className="mt-2">
                    You may not use as a username the name of another person or
                    entity or that is not lawfully available for use, a name or
                    trademark that is subject to any rights of another person or
                    entity other than you, without appropriate authorization.
                    You may not use as a username any name that is offensive,
                    vulgar or obscene.
                  </p>
                  <p className="mt-2">
                    We reserve the right to refuse service, terminate accounts,
                    remove or edit content, or cancel orders in our sole
                    discretion.
                  </p>
                </div>

                <div className="description">
                  <h2 id="intellectual">14. Intellectual Property</h2>
                  <p className="mt-2">
                    Service and its original content (excluding Content provided
                    by users), features and functionality are and will remain
                    the exclusive property of Ilmiya and its licensors. Service
                    is protected by copyright, trademark, and other laws of the
                    United States and foreign countries. Our trademarks and
                    trade dress may not be used in connection with any product
                    or service without the prior written consent of Ilmiya.
                  </p>
                </div>

                <div className="description">
                  <h2 id="policy">15. Copyright Policy</h2>
                  <p className="mt-2">
                    We respect the intellectual property rights of others. It is
                    our policy to respond to any claim that Content posted on
                    Service infringes on the copyright or other intellectual
                    property rights (“Infringement”) of any person or entity.
                  </p>
                  <p className="mt-2">
                    If you are a copyright owner, or authorized on behalf of
                    one, and you believe that the copyrighted work has been
                    copied in a way that constitutes copyright infringement,
                    please submit your claim via email to{" "}
                    <a href="#"><span> support@ilmiya.com</span></a> , with the subject line:
                    “Copyright Infringement” and include in your claim a
                    detailed description of the alleged Infringement as detailed
                    below, under “DMCA Notice and Procedure for Copyright
                    Infringement Claims”
                  </p>
                  <p className="mt-2">
                    You may be held accountable for damages (including costs and
                    attorneys' fees) for misrepresentation or bad-faith claims
                    on the infringement of any Content found on and/or through
                    Service on your copyright.
                  </p>
                </div>

                <div className="description">
                  <h2 id="notice">
                    16. DMCA Notice and Procedure for Copyright Infringement
                    Claims
                  </h2>
                  <p className="mt-2">
                    You may submit a notification pursuant to the Digital
                    Millennium Copyright Act (DMCA) by providing our Copyright
                    Agent with the following information in writing (see 17
                    U.S.C 512(c)(3) for further detail):
                  </p>
                  <ul>
                    <li>
                      <p className="mt-2">
                        An electronic or physical signature of the person
                        authorized to act on behalf of the owner of the
                        copyright's interest;
                      </p>
                    </li>
                  </ul>

                  <ul>
                    <li>
                      <p className="mt-2">
                        A description of the copyrighted work that you claim has
                        been infringed, including the URL (i.e., web page
                        address) of the location where the copyrighted work
                        exists or a copy of the copyrighted work;
                      </p>
                    </li>
                  </ul>

                  <ul>
                    <li>
                      <p className="mt-2">
                        Identification of the URL or other specific location on
                        Service where the material that you claim is infringing
                        is located;
                      </p>
                    </li>
                  </ul>

                  <p className="mt-2">Your address, telephone number, and email address;</p>

                  <ul>
                    <li>
                      <p className="mt-2">
                        A statement by you that you have a good faith belief
                        that the disputed use is not authorized by the copyright
                        owner, its agent, or the law;
                      </p>
                    </li>
                  </ul>

                  <ul>
                    <li>
                      <p className="mt-2">
                        A statement by you, made under penalty of perjury, that
                        the above information in your notice is accurate and
                        that you are the copyright owner or authorized to act on
                        the copyright owner's behalf.
                      </p>
                    </li>
                  </ul>

                  <p className="mt-2">
                    You can contact our Copyright Agent via email at{" "}
                    <a href="#"><span> support@ilmiya.com</span></a>
                  </p>
                </div>

                <div className="description">
                  <h2 id="error">17. Error Reporting and Feedback</h2>
                  <p className="mt-2">
                    You may provide us either directly at{" "}
                    <a href="#"><span> support@ilmiya.com</span></a>
                    or via third party sites and tools with information and
                    feedback concerning errors, suggestions for improvements,
                    ideas, problems, complaints, and other matters related to
                    our Service (“Feedback”). You acknowledge and agree that:
                    (i) you shall not retain, acquire or assert any intellectual
                    property right or other right, title or interest in or to
                    the Feedback; (ii) Company may have development ideas
                    similar to the Feedback; (iii) Feedback does not contain
                    confidential information or proprietary information from you
                    or any third party; and (iv) Company is not under any
                    obligation of confidentiality with respect to the Feedback.
                    In the event the transfer of the ownership to the Feedback
                    is not possible due to applicable mandatory laws, you grant
                    Company and its affiliates an exclusive, transferable,
                    irrevocable, free-of-charge, sub-licensable, unlimited and
                    perpetual right to use (including copy, modify, create
                    derivative works, publish, distribute and commercialize)
                    Feedback in any manner and for any purpose.
                  </p>
                </div>

                <div className="description">
                  <h2 id="links-to-site">18. Links To Other Web Sites</h2>
                  <p className="mt-2">
                    Our Service may contain links to third party web sites or
                    services that are not owned or controlled by Ilmiya
                  </p>
                  <p className="mt-2">
                    Ilmiya has no control over, and assumes no responsibility
                    for the content, privacy policies, or practices of any third
                    party web sites or services. We do not warrant the offerings
                    of any of these entities/individuals or their websites.
                  </p>
                  <p className="mt-2">
                    YOU ACKNOWLEDGE AND AGREE THAT ILMIYA SHALL NOT BE
                    RESPONSIBLE OR LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY
                    DAMAGE OR LOSS CAUSED OR ALLEGED TO BE CAUSED BY OR IN
                    CONNECTION WITH USE OF OR RELIANCE ON ANY SUCH CONTENT,
                    GOODS OR SERVICES AVAILABLE ON OR THROUGH ANY SUCH THIRD
                    PARTY WEB SITES OR SERVICES.
                  </p>
                  <p className="mt-2">
                    WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND
                    PRIVACY POLICIES OF ANY THIRD PARTY WEB SITES OR SERVICES
                    THAT YOU VISIT.
                  </p>
                </div>

                <div className="description">
                  <h2 id="warranty"> 19. Disclaimer of Warranty</h2>
                  <p className="mt-2">
                    THESE SERVICES ARE PROVIDED BY COMPANY ON AN “AS IS” AND “AS
                    AVAILABLE” BASIS. COMPANY MAKES NO REPRESENTATIONS OR
                    WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE
                    OPERATION OF THEIR SERVICES, OR THE INFORMATION, CONTENT OR
                    MATERIALS INCLUDED THEREIN. YOU EXPRESSLY AGREE THAT YOUR
                    USE OF THESE SERVICES, THEIR CONTENT, AND ANY SERVICES OR
                    ITEMS OBTAINED FROM US IS AT YOUR SOLE RISK.
                  </p>
                  <p className="mt-2">
                    NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH COMPANY MAKES
                    ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE
                    COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR
                    AVAILABILITY OF THE SERVICES. WITHOUT LIMITING THE
                    FOREGOING, NEITHER COMPANY NOR ANYONE ASSOCIATED WITH
                    COMPANY REPRESENTS OR WARRANTS THAT THE SERVICES, THEIR
                    CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE
                    SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR
                    UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE
                    SERVICES OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF
                    VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SERVICES OR
                    ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL
                    OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
                  </p>
                  <p className="mt-2">
                    COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
                    EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT
                    NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY,
                    NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.
                  </p>
                  <p className="mt-2">
                    THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE
                    EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
                  </p>
                </div>

                <div className="description">
                  <h2 id="liability">20. Limitation Of Liability</h2>
                  <p className="mt-2">
                    EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR
                    OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR ANY
                    INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL
                    DAMAGE, HOWEVER IT ARISES (INCLUDING ATTORNEYS' FEES AND ALL
                    RELATED COSTS AND EXPENSES OF LITIGATION AND ARBITRATION, OR
                    AT TRIAL OR ON APPEAL, IF ANY, WHETHER OR NOT LITIGATION OR
                    ARBITRATION IS INSTITUTED), WHETHER IN AN ACTION OF
                    CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR ARISING
                    OUT OF OR IN CONNECTION WITH THIS AGREEMENT, INCLUDING
                    WITHOUT LIMITATION ANY CLAIM FOR PERSONAL INJURY OR PROPERTY
                    DAMAGE, ARISING FROM THIS AGREEMENT AND ANY VIOLATION BY YOU
                    OF ANY FEDERAL, STATE, OR LOCAL LAWS, STATUTES, RULES, OR
                    REGULATIONS, EVEN IF COMPANY HAS BEEN PREVIOUSLY ADVISED OF
                    THE POSSIBILITY OF SUCH DAMAGE. EXCEPT AS PROHIBITED BY LAW,
                    IF THERE IS LIABILITY FOUND ON THE PART OF COMPANY, IT WILL
                    BE LIMITED TO THE AMOUNT PAID FOR THE PRODUCTS AND/OR
                    SERVICES, AND UNDER NO CIRCUMSTANCES WILL THERE BE
                    CONSEQUENTIAL OR PUNITIVE DAMAGES. SOME STATES DO NOT ALLOW
                    THE EXCLUSION OR LIMITATION OF PUNITIVE, INCIDENTAL OR
                    CONSEQUENTIAL DAMAGES, SO THE PRIOR LIMITATION OR EXCLUSION
                    MAY NOT APPLY TO YOU.
                  </p>
                </div>
                <div className="description">
                  <h2 id="termination">21. Termination</h2>
                  <p className="mt-2">
                    We may terminate or suspend your account and bar access to
                    Service immediately, without prior notice or liability,
                    under our sole discretion, for any reason whatsoever and
                    without limitation, including but not limited to a breach of
                    Terms.
                  </p>
                  <p className="mt-2">
                    If you wish to terminate your account, you may simply
                    discontinue using Service.
                  </p>
                  <p className="mt-2">
                    All provisions of Terms which by their nature should survive
                    termination shall survive termination, including, without
                    limitation, ownership provisions, warranty disclaimers,
                    indemnity and limitations of liability.
                  </p>
                </div>

                <div className="description">
                  <h2 id="govern-law">22. Governing Law</h2>
                  <p className="mt-2">
                    These Terms shall be governed and construed in accordance
                    with the laws of State of California without regard to its
                    conflict of law provisions.
                  </p>
                  <p className="mt-2">
                    Our failure to enforce any right or provision of these Terms
                    will not be considered a waiver of those rights. If any
                    provision of these Terms is held to be invalid or
                    unenforceable by a court, the remaining provisions of these
                    Terms will remain in effect. These Terms constitute the
                    entire agreement between us regarding our Service and
                    supersede and replace any prior agreements we might have had
                    between us regarding Service.
                  </p>
                </div>
                <div className="description">
                  <h2 id="service">23. Changes To Service</h2>
                  <p className="mt-2">
                    We reserve the right to withdraw or amend our Service, and
                    any service or material we provide via Service, in our sole
                    discretion without notice. We will not be liable if for any
                    reason all or any part of Service is unavailable at any time
                    or for any period. From time to time, we may restrict access
                    to some parts of Service, or the entire Service, to users,
                    including registered users.
                  </p>
                </div>
                <div className="description">
                  <h2 id="amendment">24. Amendments To Terms</h2>
                  <p className="mt-2">
                    We may amend Terms at any time by posting the amended terms
                    on this site. It is your responsibility to review these
                    Terms periodically.
                  </p>
                  <p className="mt-2">
                    Your continued use of the Platform following the posting of
                    revised Terms means that you accept and agree to the
                    changes. You are expected to check this page frequently so
                    you are aware of any changes, as they are binding on you.
                  </p>
                  <p className="mt-2">
                    By continuing to access or use our Service after any
                    revisions become effective, you agree to be bound by the
                    revised terms. If you do not agree to the new terms, you are
                    no longer authorized to use Service.
                  </p>
                </div>
                <div className="description">
                  <h2 id="waiver">25. Waiver And Severability</h2>
                  <p className="mt-2">
                    No waiver by Company of any term or condition set forth in
                    Terms shall be deemed a further or continuing waiver of such
                    term or condition or a waiver of any other term or
                    condition, and any failure of Company to assert a right or
                    provision under Terms shall not constitute a waiver of such
                    right or provision.
                  </p>
                  <p className="mt-2">
                    If any provision of Terms is held by a court or other
                    tribunal of competent jurisdiction to be invalid, illegal or
                    unenforceable for any reason, such provision shall be
                    eliminated or limited to the minimum extent such that the
                    remaining provisions of Terms will continue in full force
                    and effect.
                  </p>
                </div>
                <div className="description">
                  <h2 id="acknowledgement">26. Acknowledgement</h2>
                  <p className="mt-2">
                    BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU
                    ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF SERVICE AND
                    AGREE TO BE BOUND BY THEM.
                  </p>
                </div>
                <div className="description">
                  <h2 id="contact-us">27. Contact Us</h2>
                  <p className="mt-2">
                    Please send your feedback, comments, requests for technical
                    support:
                  </p>
                  <p className="mt-2">
                    By email:<a href="#"><span> support@ilmiya.com</span></a>
                  </p>
                </div>
              </div>
                </div>
              </div>

              {/* <div className="content-wrapper">
                <div className="description">
                  <h1>Introduction</h1>
                  <p className="mt-2">
                    Welcome to Ilmiya (“Company”, “we”, “our”, “us”)! As you
                    have just clicked our Terms of Service, please pause, grab a
                    cup of coffee and carefully read the following pages. It
                    will take you approximately 20 minutes.
                  </p>
                  <p className="mt-2">
                    These Terms of Service (“Terms”, “Terms of Service”) govern
                    your use of our web pages located at <span>ilmiya.com</span>{" "}
                    and our mobile application Ilmiya (together or individually
                    “Service”) operated by Ilmiya
                  </p>
                  <p className="mt-2">
                    Our Privacy Policy also governs your use of our Service and
                    explains how we collect, safeguard and disclose information
                    that results from your use of our web pages. Please read it
                    here <span>Privacy Policy</span>
                  </p>
                  <p className="mt-2">
                    Your agreement with us includes these Terms and our Privacy
                    Policy (“Agreements”). You acknowledge that you have read
                    and understood Agreements, and agree to be bound of them.
                  </p>
                  <p className="mt-2">
                    If you are entering into this Agreement on behalf of a
                    company or other legal entity, you represent that you have
                    the authority to bind such entity, its affiliates, and all
                    users who access the Service through your account to this
                    Agreement, in which case the terms “you” or “your” shall
                    refer to such entity, its affiliates, and users associated
                    with it. If you do not have such authority, or if you do not
                    agree with these terms and conditions, you must not accept
                    this Agreement and may not use the Service.
                  </p>
                  <p className="mt-2">
                    PLEASE READ THIS AGREEMENT CAREFULLY BEFORE ACCESSING OUR
                    SITE OR USING THE SERVICE. BY ACCESSING OR USING ANY PART OF
                    THE SITE, YOU AGREE TO BECOME BOUND BY THIS AGREEMENT. THESE
                    TERMS INCLUDE A BINDING ARBITRATION CLAUSE AND A CLASS
                    ACTION WAIVER.
                  </p>
                  <p className="mt-2">
                    If you do not agree with (or cannot comply with) Agreements,
                    then you may not use the Service, but please let us know by
                    emailing at <span>support@ilmiya.com </span> so we can try
                    to find a solution. These Terms apply to all visitors, users
                    and others who wish to access or use Service.
                  </p>
                  <p className="mt-2">Thank you for being responsible.</p>
                </div>
                <div className="description">
                  <h1>1. General Account Terms</h1>
                  <p className="mt-2">
                    In order to use our Service, you must be a human being.
                    Accounts registered by “bots” or other automated methods are
                    not permitted.
                  </p>
                  <p className="mt-2">
                    Each account may be only used by one person, and you may
                    only have one account. If it is discovered that you have
                    registered multiple accounts, are sharing accounts with
                    another individual, or are otherwise accessing the Site
                    through an account that is not your own, all accounts
                    affected by such use shall be terminated.
                  </p>
                  <p className="mt-2">
                    You are responsible for maintaining the security of your
                    account and password. Company cannot and will not be liable
                    for any loss or damage from your failure to comply with this
                    security obligation. You are fully responsible for all
                    activities that occur under the account and any other
                    actions taken in connection with the account. You must
                    immediately notify Company of any unauthorized uses of your
                    account, or any other breaches of security. We will not be
                    liable for any acts or omissions by you, including any
                    damages of any kind incurred as a result of such acts or
                    omissions.
                  </p>
                  <p className="mt-2">
                    You may not use the Service for any illegal or unauthorized
                    purpose. You must not, in the use of the Service, violate
                    any laws in your jurisdiction (including but not limited to
                    copyright or trademark laws). No matter what else is said in
                    these Terms or anywhere else within the Services, you
                    expressly acknowledge that you have no right, title, or
                    interest to or in any account you create through our
                    Services, and your account is not your property.
                  </p>
                  <p className="mt-2">
                    Company may remove Content that violates the Agreement at
                    its sole discretion. Removing Content, or terminating an
                    account are not actions we take lightly, and we may take a
                    while to investigate and determine if such actions are
                    warranted. It is at our sole discretion whether to discuss
                    the reasons for taking action against any Content or
                    account.
                  </p>
                  <p className="mt-2">
                    We reserve the right to withdraw or amend our Site, and any
                    service or material we provide on the Site, in our sole
                    discretion without notice. We will not be liable if for any
                    reason all or any part of the Site is unavailable at any
                    time or for any period. From time to time, we may restrict
                    access to some parts of the Site, or the entire Site, to
                    users, including registered users.
                  </p>
                  <p className="mt-2">You are responsible for both:</p>
                  <ul>
                    <li>
                      <p className="mt-2">
                        Making all arrangements necessary for you to have access
                        to the Site.
                      </p>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <p className="mt-2">
                        Ensuring that all persons who access the Site through
                        your internet connection are aware of these Terms of Use
                        and comply with them.
                      </p>
                    </li>
                  </ul>
                  <p className="mt-2">
                    To access the Site or some of the resources it offers, you
                    may be asked to provide certain registration details or
                    other information. It is a condition of your use of the Site
                    that all the information you provide on the Site is correct,
                    current, and complete. You agree that all information you
                    provide to register with this Site or otherwise, including,
                    but not limited to, through the use of any interactive
                    features on the Site, is governed by our Privacy Policy, and
                    you consent to all actions we take with respect to your
                    information consistent with our <span>Privacy Policy</span>{" "}
                  </p>
                </div>
                <div className="description">
                  <h4>2. Communications</h4>
                  <p className="mt-2">
                    By creating an Account on our Service, you agree to
                    subscribe to newsletters, marketing or promotional materials
                    and other information we may send. However, you may opt out
                    of receiving any, or all, of these communications from us by
                    following the unsubscribe link or by emailing at.
                  </p>
                </div>

                <div className="description">
                  <h1>3. Purchases</h1>
                  <p className="mt-2">
                    If you wish to purchase any product or service made
                    available through Service (“Purchase”), you may be asked to
                    supply certain information relevant to your Purchase
                    including, without limitation, your credit card number, the
                    expiration date of your credit card, your billing address,
                    and your shipping information.
                  </p>
                  <p className="mt-2">
                    You represent and warrant that: (i) you have the legal right
                    to use any credit card(s) or other payment method(s) in
                    connection with any Purchase; and that (ii) the information
                    you supply to us is true, correct and complete.
                  </p>
                  <p className="mt-2">
                    We may employ the use of third party services for the
                    purpose of facilitating payment and the completion of
                    Purchases. By submitting your information, you grant us the
                    right to provide the information to these third parties
                    subject to our <span> Privacy Policy</span>{" "}
                  </p>
                  <p className="mt-2">
                    We reserve the right to refuse or cancel your order at any
                    time for reasons including but not limited to: product or
                    service availability, errors in the description or price of
                    the product or service, error in your order or other
                    reasons.
                  </p>
                  <p className="mt-2">
                    We reserve the right to refuse or cancel your order if fraud
                    or an unauthorized or illegal transaction is suspected.
                  </p>
                </div>

                <div className="description">
                  <h1>4. Contests, Sweepstakes and Promotions</h1>
                  <p className="mt-2">
                    Any contests, sweepstakes or other promotions (collectively,
                    “Promotions”) made available through Service may be governed
                    by rules that are separate from these Terms of Service. If
                    you participate in any Promotions, please review the
                    applicable rules as well as our
                    <span> Privacy Policy</span> . If the rules for a Promotion
                    conflict with these Terms of Service, Promotion rules will
                    apply.
                  </p>
                </div>

                <div className="description">
                  <h1>5. Subscriptions</h1>
                  <p className="mt-2">
                    Some parts of Service are billed on a subscription basis
                    (“Subscription(s)”). You will be billed in advance on a
                    recurring and periodic basis (“Billing Cycle”). Billing
                    cycles are set either on a monthly or annual basis,
                    depending on the type of subscription plan you select when
                    purchasing a Subscription.
                  </p>
                  <p className="mt-2">
                    At the end of each Billing Cycle, your Subscription will
                    automatically renew under the exact same conditions unless
                    you cancel it or Ilmiya cancels it. You may cancel your
                    Subscription renewal either through your online account
                    management page or by contacting Ilmiya customer support
                    team.
                  </p>
                  <p className="mt-2">
                    A valid payment method, including credit card or PayPal, is
                    required to process the payment for your subscription. You
                    shall provide Ilmiya with accurate and complete billing
                    information including full name, address, state, zip code,
                    telephone number, and a valid payment method information. By
                    submitting such payment information, you automatically
                    authorize Ilmiya to charge all Subscription fees incurred
                    through your account to any such payment instruments.
                  </p>
                  <p className="mt-2">
                    Should automatic billing fail to occur for any reason,
                    Ilmiya will issue an electronic invoice indicating that you
                    must proceed manually, within a certain deadline date, with
                    the full payment corresponding to the billing period as
                    indicated on the invoice.
                  </p>
                </div>

                <div className="description">
                  <h1>6. Free Trial</h1>
                  <p className="mt-2">
                    Ilmiya may, at its sole discretion, offer a Subscription
                    with a free trial for a limited period of time (“Free
                    Trial”).
                  </p>
                  <p className="mt-2">
                    You may be required to enter your billing information in
                    order to sign up for Free Trial.
                  </p>
                  <p className="mt-2">
                    If you do enter your billing information when signing up for
                    Free Trial, you will not be charged by Ilmiya until Free
                    Trial has expired. On the last day of Free Trial period,
                    unless you cancelled your Subscription, you will be
                    automatically charged the applicable Subscription fees for
                    the type of Subscription you have selected.
                  </p>
                  <p className="mt-2">
                    At any time and without notice, Ilmiya reserves the right to
                    (i) modify Terms of Service of Free Trial offer, or (ii)
                    cancel such Free Trial offer.
                  </p>
                </div>

                <div className="description">
                  <h1>7. Fee Changes</h1>
                  <p className="mt-2">
                    Ilmiya, in its sole discretion and at any time, may modify
                    Subscription fees for the Subscriptions. Any Subscription
                    fee change will become effective at the end of the
                    then-current Billing Cycle.
                  </p>
                  <p className="mt-2">
                    Ilmiya will provide you with a reasonable prior notice of
                    any change in Subscription fees to give you an opportunity
                    to terminate your Subscription before such change becomes
                    effective.
                  </p>
                  <p className="mt-2">
                    Your continued use of Service after Subscription fee change
                    comes into effect constitutes your agreement to pay the
                    modified Subscription fee amount.
                  </p>
                </div>

                <div className="description">
                  <h1>8. Refunds & Cancellation</h1>
                  <p className="mt-2">
                    Except when required by law, paid Subscription fees are
                    non-refundable.
                  </p>
                  <p className="mt-2">
                    One month (30 days) advance written notice is due on the 1st
                    of the current month to discontinue Ilmiya services. Failure
                    to give notice by the 1st of the current month will result
                    in enrollment for the following month and termination
                    occurring the month after. No exceptions. Paid tuition is
                    non-refundable regardless of duration.
                  </p>
                  <p className="mt-2">
                    {" "}
                    Cancellation submitted days 1st - 5th of the month:
                    Termination/changes occur next month.
                  </p>
                  <p className="mt-2">
                    Cancellation submitted days 6th - 31st of the month:
                    Enrolled next month, termination/changes occur the month
                    after.
                  </p>
                  <p className="mt-2">
                    Please provide Ilmiya an advance notice of absences,
                    students almost invariably lose momentum and regress with
                    periods of a week or more of not doing daily practice. It is
                    advisable to take work with you on vacations and have them
                    do it daily. Ilmiya allows one session absences per calendar
                    year before the student is dropped from enrollment. Absences
                    over three sessions in a calendar year require re-enrollment
                    with the associated costs.
                  </p>
                </div>
                <div className="description">
                  <h1>9. Content</h1>
                  <p className="mt-2">
                    Our Service allows you to post, link, store, share and
                    otherwise make available certain information, text,
                    graphics, videos, or other material (“Content”). You are
                    responsible for Content that you post on or through Service,
                    including its legality, reliability, and appropriateness.
                  </p>
                  <p className="mt-2">
                    By posting Content on or through Service, You represent and
                    warrant that: (i) Content is yours (you own it) and/or you
                    have the right to use it and the right to grant us the
                    rights and license as provided in these Terms, and (ii) that
                    the posting of your Content on or through Service does not
                    violate the privacy rights, publicity rights, copyrights,
                    contract rights or any other rights of any person or entity.
                    We reserve the right to terminate the account of anyone
                    found to be infringing on a copyright.
                  </p>
                  <p className="mt-2">
                    You retain any and all of your rights to any Content you
                    submit, post or display on or through Service and you are
                    responsible for protecting those rights. We take no
                    responsibility and assume no liability for Content you or
                    any third party posts on or through Service. However, by
                    posting Content using Service you grant us the right and
                    license to use, modify, publicly perform, publicly display,
                    reproduce, and distribute such Content on and through
                    Service. You agree that this license includes the right for
                    us to make your Content available to other users of Service,
                    who may also use your Content subject to these Terms.
                  </p>
                  <p className="mt-2">
                    Ilmiya has the right but not the obligation to monitor and
                    edit all Content provided by users.
                  </p>
                  <p className="mt-2">
                    In addition, Content found on or through this Service are
                    the property of Ilmiya or used with permission. You may not
                    distribute, modify, transmit, reuse, download, repost, copy,
                    or use said Content, whether in whole or in part, for
                    commercial purposes or for personal gain, without express
                    advance written permission from us.
                  </p>
                </div>
                <div className="description">
                  <h1>10. Prohibited Uses</h1>
                  <p className="mt-2">
                    You may use Service only for lawful purposes and in
                    accordance with Terms. You agree not to use Service:
                  </p>
                  <p className="mt-2">
                    In any way that violates any applicable national or
                    international law or regulation.
                  </p>
                  <p className="mt-2">
                    For the purpose of exploiting, harming, or attempting to
                    exploit or harm minors in any way by exposing them to
                    inappropriate content or otherwise.
                  </p>
                  <p className="mt-2">
                    To transmit, or procure the sending of, any advertising or
                    promotional material, including any “junk mail”, “chain
                    letter,” “spam,” or any other similar solicitation.
                  </p>
                  <p className="mt-2">
                    To impersonate or attempt to impersonate Company, a Company
                    employee, another user, or any other person or entity.
                  </p>
                  <p className="mt-2">
                    In any way that infringes upon the rights of others, or in
                    any way is illegal, threatening, fraudulent, or harmful, or
                    in connection with any unlawful, illegal, fraudulent, or
                    harmful purpose or activity.
                  </p>
                  <p className="mt-2">
                    To engage in any other conduct that restricts or inhibits
                    anyone’s use or enjoyment of Service, or which, as
                    determined by us, may harm or offend Company or users of
                    Service or expose them to liability.
                  </p>
                  <p className="mt-2">
                    To use the Services to enable any person (including you) to
                    benefit from any activities Stripe has identified as a
                    restricted business or activity (collectively, “Restricted
                    Businesses”). Restricted Businesses include use of the
                    Services in or for the benefit of a country, organization,
                    entity, or person embargoed or blocked by any government,
                    including those on sanctions lists identified by the United
                    States Office of Foreign Asset Control (OFAC).
                  </p>
                  <p className="mt-2">
                    Please review the list of Restricted Businesses thoroughly
                    before registering for and opening a Ilmiya Account. If you
                    are uncertain whether a category of business or activity is
                    restricted or have questions about how these restrictions
                    apply to you, please contact us. We may add to or update the
                    Restricted Business List at any time.
                  </p>
                  <p className="mt-2">Additionally, you agree not to:</p>
                  <ul>
                    <li>
                      <p className="mt-2">
                        Use Service in any manner that could disable,
                        overburden, damage, or impair Service or interfere with
                        any other party’s use of Service, including their
                        ability to engage in real time activities through
                        Service.
                      </p>
                    </li>
                  </ul>

                  <ul>
                    <li>
                      <p className="mt-2">
                        Use any robot, spider, or other automatic device,
                        process, or means to access Service for any purpose,
                        including monitoring or copying any of the material on
                        Service.
                      </p>
                    </li>
                  </ul>

                  <ul>
                    <li>
                      <p className="mt-2">
                        Use any manual process to monitor or copy any of the
                        material on Service or for any other unauthorized
                        purpose without our prior written consent.
                      </p>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <p className="mt-2">
                        Use any device, software, or routine that interferes
                        with the proper working of Service.
                      </p>
                    </li>
                  </ul>

                  <ul>
                    <li>
                      <p className="mt-2">
                        Introduce any viruses, trojan horses, worms, logic
                        bombs, or other material which is malicious or
                        technologically harmful.
                      </p>
                    </li>
                  </ul>

                  <ul>
                    <li>
                      <p className="mt-2">
                        Attempt to gain unauthorized access to, interfere with,
                        damage, or disrupt any parts of Service, the server on
                        which Service is stored, or any server, computer, or
                        database connected to Service.
                      </p>
                    </li>
                  </ul>

                  <ul>
                    <li>
                      <p className="mt-2">
                        Attack Service via a denial-of-service attack or a
                        distributed denial-of-service attack.
                      </p>
                    </li>
                  </ul>

                  <ul>
                    <li>
                      <p className="mt-2">
                        Take any action that may damage or falsify Company
                        rating.
                      </p>
                    </li>
                  </ul>

                  <ul>
                    <li>
                      <p className="mt-2">
                        Otherwise attempt to interfere with the proper working
                        of Service.
                      </p>
                    </li>
                  </ul>
                </div>

                <div className="description">
                  <h1>11. Analytics</h1>
                  <p className="mt-2">
                    We may use third-party Service Providers to monitor and
                    analyze the use of our Service. For more details please
                    refer to our privacy policy at{" "}
                  </p>
                </div>

                <div className="description">
                  <h1>12. Age Requirements</h1>
                  <p className="mt-2">
                    By accessing our services, you confirm that you’re at least
                    13 years old and meet the minimum age of digital consent in
                    your country. If you are old enough to access our services
                    in your country, but not old enough to have authority to
                    consent to our terms, your parent or guardian must agree to
                    our terms on your behalf. Please ask your parent or guardian
                    to read these terms with you. If you’re a parent or legal
                    guardian, and you allow your teenager to use the services,
                    then these terms also apply to you and you’re responsible
                    for your teenager’s activity on the services.
                  </p>
                  <p className="mt-2">
                    Ilmiya understands the importance of safeguarding the
                    personal data of children, which we consider to be an
                    individual under the age of 13 or the equivalent age as
                    specified by law in your jurisdiction. That is why Ilmiya
                    has implemented additional processes and protections to help
                    keep children's personal data safe.
                  </p>
                  <p className="mt-2">
                    If you are under the age of 13, you may not create or
                    register an account for the Services without consent and
                    approval from your legal parent or guardian, or from your
                    educational institution. If you are under the age of 18, you
                    represent that your parent or legal guardian has reviewed
                    and agreed to the Terms on your behalf. A Child User will
                    only be permitted to use the Services for so long as Ilmiya
                    reasonably believes that such access has been consented to
                    by the Child User’s parent or guardian, or by their
                    educational institution. To access certain Ilmiya services,
                    a child must have an Ilmiya account. An Ilmiya account may
                    be created by the parent or, in the case of a managed by the
                    child's institution. If we learn that a child's personal
                    data was collected without appropriate authorization, it
                    will be deleted as soon as possible.
                  </p>
                </div>

                <div className="description">
                  <h1>13. Accounts</h1>
                  <p className="mt-2">
                    When you create an account with us, you guarantee that you
                    are above the age of 13, and that the information you
                    provide us is accurate, complete, and current at all times.
                    Inaccurate, incomplete, or obsolete information may result
                    in the immediate termination of your account on Service.
                  </p>
                  <p className="mt-2">
                    You are responsible for maintaining the confidentiality of
                    your account and password, including but not limited to the
                    restriction of access to your computer and/or account. You
                    agree to accept responsibility for any and all activities or
                    actions that occur under your account and/or password,
                    whether your password is with our Service or a third-party
                    service. You must notify us immediately upon becoming aware
                    of any breach of security or unauthorized use of your
                    account.
                  </p>
                  <p className="mt-2">
                    You may not use as a username the name of another person or
                    entity or that is not lawfully available for use, a name or
                    trademark that is subject to any rights of another person or
                    entity other than you, without appropriate authorization.
                    You may not use as a username any name that is offensive,
                    vulgar or obscene.
                  </p>
                  <p className="mt-2">
                    We reserve the right to refuse service, terminate accounts,
                    remove or edit content, or cancel orders in our sole
                    discretion.
                  </p>
                </div>

                <div className="description">
                  <h1>14. Intellectual Property</h1>
                  <p className="mt-2">
                    Service and its original content (excluding Content provided
                    by users), features and functionality are and will remain
                    the exclusive property of Ilmiya and its licensors. Service
                    is protected by copyright, trademark, and other laws of the
                    United States and foreign countries. Our trademarks and
                    trade dress may not be used in connection with any product
                    or service without the prior written consent of Ilmiya.
                  </p>
                </div>

                <div className="description">
                  <h1>15. Copyright Policy</h1>
                  <p className="mt-2">
                    We respect the intellectual property rights of others. It is
                    our policy to respond to any claim that Content posted on
                    Service infringes on the copyright or other intellectual
                    property rights (“Infringement”) of any person or entity.
                  </p>
                  <p className="mt-2">
                    If you are a copyright owner, or authorized on behalf of
                    one, and you believe that the copyrighted work has been
                    copied in a way that constitutes copyright infringement,
                    please submit your claim via email to{" "}
                    <span> support@ilmiya.com</span> , with the subject line:
                    “Copyright Infringement” and include in your claim a
                    detailed description of the alleged Infringement as detailed
                    below, under “DMCA Notice and Procedure for Copyright
                    Infringement Claims”
                  </p>
                  <p className="mt-2">
                    You may be held accountable for damages (including costs and
                    attorneys' fees) for misrepresentation or bad-faith claims
                    on the infringement of any Content found on and/or through
                    Service on your copyright.
                  </p>
                </div>

                <div className="description">
                  <h1>
                    16. DMCA Notice and Procedure for Copyright Infringement
                    Claims
                  </h1>
                  <p className="mt-2">
                    You may submit a notification pursuant to the Digital
                    Millennium Copyright Act (DMCA) by providing our Copyright
                    Agent with the following information in writing (see 17
                    U.S.C 512(c)(3) for further detail):
                  </p>
                  <ul>
                    <li>
                      <p className="mt-2">
                        An electronic or physical signature of the person
                        authorized to act on behalf of the owner of the
                        copyright's interest;
                      </p>
                    </li>
                  </ul>

                  <ul>
                    <li>
                      <p className="mt-2">
                        A description of the copyrighted work that you claim has
                        been infringed, including the URL (i.e., web page
                        address) of the location where the copyrighted work
                        exists or a copy of the copyrighted work;
                      </p>
                    </li>
                  </ul>

                  <ul>
                    <li>
                      <p className="mt-2">
                        Identification of the URL or other specific location on
                        Service where the material that you claim is infringing
                        is located;
                      </p>
                    </li>
                  </ul>

                  <p className="mt-2">Your address, telephone number, and email address;</p>

                  <ul>
                    <li>
                      <p className="mt-2">
                        A statement by you that you have a good faith belief
                        that the disputed use is not authorized by the copyright
                        owner, its agent, or the law;
                      </p>
                    </li>
                  </ul>

                  <ul>
                    <li>
                      <p className="mt-2">
                        A statement by you, made under penalty of perjury, that
                        the above information in your notice is accurate and
                        that you are the copyright owner or authorized to act on
                        the copyright owner's behalf.
                      </p>
                    </li>
                  </ul>

                  <p className="mt-2">
                    You can contact our Copyright Agent via email at{" "}
                    <span>support@ilmiya.com</span>
                  </p>
                </div>

                <div className="description">
                  <h1>17. Error Reporting and Feedback</h1>
                  <p className="mt-2">
                    You may provide us either directly at{" "}
                    <span> support@ilmiya.com</span>
                    or via third party sites and tools with information and
                    feedback concerning errors, suggestions for improvements,
                    ideas, problems, complaints, and other matters related to
                    our Service (“Feedback”). You acknowledge and agree that:
                    (i) you shall not retain, acquire or assert any intellectual
                    property right or other right, title or interest in or to
                    the Feedback; (ii) Company may have development ideas
                    similar to the Feedback; (iii) Feedback does not contain
                    confidential information or proprietary information from you
                    or any third party; and (iv) Company is not under any
                    obligation of confidentiality with respect to the Feedback.
                    In the event the transfer of the ownership to the Feedback
                    is not possible due to applicable mandatory laws, you grant
                    Company and its affiliates an exclusive, transferable,
                    irrevocable, free-of-charge, sub-licensable, unlimited and
                    perpetual right to use (including copy, modify, create
                    derivative works, publish, distribute and commercialize)
                    Feedback in any manner and for any purpose.
                  </p>
                </div>

                <div className="description">
                  <h1>18. Links To Other Web Sites</h1>
                  <p className="mt-2">
                    Our Service may contain links to third party web sites or
                    services that are not owned or controlled by Ilmiya
                  </p>
                  <p className="mt-2">
                    Ilmiya has no control over, and assumes no responsibility
                    for the content, privacy policies, or practices of any third
                    party web sites or services. We do not warrant the offerings
                    of any of these entities/individuals or their websites.
                  </p>
                  <p className="mt-2">
                    YOU ACKNOWLEDGE AND AGREE THAT ILMIYA SHALL NOT BE
                    RESPONSIBLE OR LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY
                    DAMAGE OR LOSS CAUSED OR ALLEGED TO BE CAUSED BY OR IN
                    CONNECTION WITH USE OF OR RELIANCE ON ANY SUCH CONTENT,
                    GOODS OR SERVICES AVAILABLE ON OR THROUGH ANY SUCH THIRD
                    PARTY WEB SITES OR SERVICES.
                  </p>
                  <p className="mt-2">
                    WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND
                    PRIVACY POLICIES OF ANY THIRD PARTY WEB SITES OR SERVICES
                    THAT YOU VISIT.
                  </p>
                </div>

                <div className="description">
                  <h1> 19. Disclaimer of Warranty</h1>
                  <p className="mt-2">
                    THESE SERVICES ARE PROVIDED BY COMPANY ON AN “AS IS” AND “AS
                    AVAILABLE” BASIS. COMPANY MAKES NO REPRESENTATIONS OR
                    WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE
                    OPERATION OF THEIR SERVICES, OR THE INFORMATION, CONTENT OR
                    MATERIALS INCLUDED THEREIN. YOU EXPRESSLY AGREE THAT YOUR
                    USE OF THESE SERVICES, THEIR CONTENT, AND ANY SERVICES OR
                    ITEMS OBTAINED FROM US IS AT YOUR SOLE RISK.
                  </p>
                  <p className="mt-2">
                    NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH COMPANY MAKES
                    ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE
                    COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR
                    AVAILABILITY OF THE SERVICES. WITHOUT LIMITING THE
                    FOREGOING, NEITHER COMPANY NOR ANYONE ASSOCIATED WITH
                    COMPANY REPRESENTS OR WARRANTS THAT THE SERVICES, THEIR
                    CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE
                    SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR
                    UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE
                    SERVICES OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF
                    VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SERVICES OR
                    ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL
                    OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
                  </p>
                  <p className="mt-2">
                    COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
                    EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT
                    NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY,
                    NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.
                  </p>
                  <p className="mt-2">
                    THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE
                    EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
                  </p>
                </div>

                <div className="description">
                  <h1>20. Limitation Of Liability</h1>
                  <p className="mt-2">
                    EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR
                    OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR ANY
                    INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL
                    DAMAGE, HOWEVER IT ARISES (INCLUDING ATTORNEYS' FEES AND ALL
                    RELATED COSTS AND EXPENSES OF LITIGATION AND ARBITRATION, OR
                    AT TRIAL OR ON APPEAL, IF ANY, WHETHER OR NOT LITIGATION OR
                    ARBITRATION IS INSTITUTED), WHETHER IN AN ACTION OF
                    CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR ARISING
                    OUT OF OR IN CONNECTION WITH THIS AGREEMENT, INCLUDING
                    WITHOUT LIMITATION ANY CLAIM FOR PERSONAL INJURY OR PROPERTY
                    DAMAGE, ARISING FROM THIS AGREEMENT AND ANY VIOLATION BY YOU
                    OF ANY FEDERAL, STATE, OR LOCAL LAWS, STATUTES, RULES, OR
                    REGULATIONS, EVEN IF COMPANY HAS BEEN PREVIOUSLY ADVISED OF
                    THE POSSIBILITY OF SUCH DAMAGE. EXCEPT AS PROHIBITED BY LAW,
                    IF THERE IS LIABILITY FOUND ON THE PART OF COMPANY, IT WILL
                    BE LIMITED TO THE AMOUNT PAID FOR THE PRODUCTS AND/OR
                    SERVICES, AND UNDER NO CIRCUMSTANCES WILL THERE BE
                    CONSEQUENTIAL OR PUNITIVE DAMAGES. SOME STATES DO NOT ALLOW
                    THE EXCLUSION OR LIMITATION OF PUNITIVE, INCIDENTAL OR
                    CONSEQUENTIAL DAMAGES, SO THE PRIOR LIMITATION OR EXCLUSION
                    MAY NOT APPLY TO YOU.
                  </p>
                </div>
                <div className="description">
                  <h1>21. Termination</h1>
                  <p className="mt-2">
                    We may terminate or suspend your account and bar access to
                    Service immediately, without prior notice or liability,
                    under our sole discretion, for any reason whatsoever and
                    without limitation, including but not limited to a breach of
                    Terms.
                  </p>
                  <p className="mt-2">
                    If you wish to terminate your account, you may simply
                    discontinue using Service.
                  </p>
                  <p className="mt-2">
                    All provisions of Terms which by their nature should survive
                    termination shall survive termination, including, without
                    limitation, ownership provisions, warranty disclaimers,
                    indemnity and limitations of liability.
                  </p>
                </div>

                <div className="description">
                  <h1>22. Governing Law</h1>
                  <p className="mt-2">
                    These Terms shall be governed and construed in accordance
                    with the laws of State of California without regard to its
                    conflict of law provisions.
                  </p>
                  <p className="mt-2">
                    Our failure to enforce any right or provision of these Terms
                    will not be considered a waiver of those rights. If any
                    provision of these Terms is held to be invalid or
                    unenforceable by a court, the remaining provisions of these
                    Terms will remain in effect. These Terms constitute the
                    entire agreement between us regarding our Service and
                    supersede and replace any prior agreements we might have had
                    between us regarding Service.
                  </p>
                </div>
                <div className="description">
                  <h1>23. Changes To Service</h1>
                  <p className="mt-2">
                    We reserve the right to withdraw or amend our Service, and
                    any service or material we provide via Service, in our sole
                    discretion without notice. We will not be liable if for any
                    reason all or any part of Service is unavailable at any time
                    or for any period. From time to time, we may restrict access
                    to some parts of Service, or the entire Service, to users,
                    including registered users.
                  </p>
                </div>
                <div className="description">
                  <h1>24. Amendments To Terms</h1>
                  <p className="mt-2">
                    We may amend Terms at any time by posting the amended terms
                    on this site. It is your responsibility to review these
                    Terms periodically.
                  </p>
                  <p className="mt-2">
                    Your continued use of the Platform following the posting of
                    revised Terms means that you accept and agree to the
                    changes. You are expected to check this page frequently so
                    you are aware of any changes, as they are binding on you.
                  </p>
                  <p className="mt-2">
                    By continuing to access or use our Service after any
                    revisions become effective, you agree to be bound by the
                    revised terms. If you do not agree to the new terms, you are
                    no longer authorized to use Service.
                  </p>
                </div>
                <div className="description">
                  <h1>25. Waiver And Severability</h1>
                  <p className="mt-2">
                    No waiver by Company of any term or condition set forth in
                    Terms shall be deemed a further or continuing waiver of such
                    term or condition or a waiver of any other term or
                    condition, and any failure of Company to assert a right or
                    provision under Terms shall not constitute a waiver of such
                    right or provision.
                  </p>
                  <p className="mt-2">
                    If any provision of Terms is held by a court or other
                    tribunal of competent jurisdiction to be invalid, illegal or
                    unenforceable for any reason, such provision shall be
                    eliminated or limited to the minimum extent such that the
                    remaining provisions of Terms will continue in full force
                    and effect.
                  </p>
                </div>
                <div className="description">
                  <h1>26. Acknowledgement</h1>
                  <p className="mt-2">
                    BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU
                    ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF SERVICE AND
                    AGREE TO BE BOUND BY THEM.
                  </p>
                </div>
                <di className="description">
                  <h1>27. Contact Us</h1>
                  <p className="mt-2">
                    Please send your feedback, comments, requests for technical
                    support:
                  </p>
                  <p className="mt-2">
                    By email: <span> support@ilmiya.com</span>
                  </p>
                </di>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <SecondaryFooter />
    </div>
  );
}
