import SecondaryFooter from "../components/SecondaryFooter";
import SecondaryHeader from "../components/SecondaryHeader";
import arrowRight from "../images/icons/arrow-right.svg";
import arrowRightBlack from "../images/icons/arrow-right-black.svg";
import signUpBanner from "../images/signup-banner.svg";
import signUpBannerDark from "../images/site-map-img.svg";
import { Link } from "react-router-dom";
export default function SignupPage() {
  return (
    <>
      <SecondaryHeader />
      <div className="signup-page-wrapper ">
        <div className="page-content container">
          <div className="row justify-content-between">
            <div className="col-md-6">
              <div className="content-wrapper">
                <div className="title">
                  <h1>
                    Go board-less <br /> <span>Go borderless</span>
                  </h1>
                </div>
                <p className="description">
                  Focus on what you do best—changing students' lives. Go
                  borderless!Expand your impact with Ilmiya’s global
                  infrastructure, designed to help you reach and inspire
                  learners everywhere.
                </p>
              </div>
              <div className="buttons-wrapper">
                <div className="login-button">
                  <Link to={"https://myilmiya.app/"} target="_blank">
                    Students
                    <img src={arrowRight} alt="arrow icon" />
                  </Link>
                </div>
                <div className="login-options">
                  <div className="single-option">
                    <Link to={"/contact-us"}>
                      Schools
                      <img src={arrowRightBlack} alt="arrow icon" />
                    </Link>
                  </div>
                  {/* <div className="single-option">
                    <Link to={"#"}>
                      For Publishers
                      <img src={arrowRightBlack} alt="arrow icon" />
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="signup-banner">
                <img
                  src={signUpBanner}
                  alt="banner"
                  className="light-theme-img"
                />
                <img
                  src={signUpBannerDark}
                  alt="banner"
                  className="dark-theme-img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <SecondaryFooter />
    </>
  );
}
