import {React, useEffect, useState} from 'react';

import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import { IntercomProvider, useIntercom } from 'react-use-intercom';
import Intercom from "@intercom/messenger-js-sdk";
import CryptoJS from 'crypto-js'
import "bootstrap/dist/css/bootstrap.min.css";

import "./App.css";
import "./assets/scss/main.scss";

import AboutUsPage from "./assets/pages/AboutUs";
import ContactUsPage from "./assets/pages/ContactUs";
import HomePage from "./assets/pages/HomePage";
import SignupPage from "./assets/pages/SignupPage";
import LegalPage from "./assets/pages/LegalPage";
import LegalDetailPage from "./assets/pages/LegalDetail";
import NotFound from "./assets/pages/NotFound";
import ThankYouPage from "./assets/pages/ThankYou";
import HomepageNew from "./assets/pages/HomePageNew";
import RouterPage from "./assets/pages/Router";
import Usagepolicy from "./assets/pages/Usagepolicy";
import Dataprotection from "./assets/pages/Dataprotection";
import Privacypolicy from "./assets/pages/Privacypolicy";
import Termsofprivacy from "./assets/pages/Termsofprivacy";
import PrivacyDisclouser from "./assets/pages/PrivacyDisclouser";
import ParentalConsent from "./assets/pages/ParentalConsent";
import StudentPrivacy from "./assets/pages/StudentPrivacy";
import MarkUsage from "./assets/pages/MarkUsage";
import UsageGuidelines from "./assets/pages/UsageGuidelines";
import SubProcessor from "./assets/pages/SubProcessor";
import Brand from "./assets/pages/Brand";
import AboutNew from "./assets/pages/AboutNew";
import NewHome from "./assets/pages/NewHome";
import AboutDemoPageSideModals from "./assets/pages/AboutDemoPageSideModals";
// import EdCloud from "./assets/pages/EdCloud";
import LetterPage from "./assets/pages/LetterPage";
import Welcome from "./assets/pages/Welcome";
import ThanksPage from "./assets/pages/ThanksPage";
import OrgCreate from "./assets/pages/OrgCreate";
import InvitedPage from "./assets/pages/InvitedPage";
import SignupWelcome from "./assets/pages/SignupWelcome";
import VerifyPage from "./assets/pages/VerifyPage";
import MemberPage from "./assets/pages/MemberPage";
import OrganizationPage from "./assets/pages/OrganizationPage";
import InterComChat from "./assets/components/InterComChat";
import {IoChatbubblesSharp} from "react-icons/io5";
import LegalCenterDashboard from "./assets/pages/LegalCenterDashboard";


function App() {

    const user = {userId : '21'};

    const INTERCOM_APP_ID = "k12qxhbg";
    const secretKey = "you_key"

    const userId = '123';
    const hash = CryptoJS.HmacSHA256(userId, secretKey).toString(CryptoJS.enc.Hex);

/*    const initIntercom = () => {
        Intercom("boot", { app_id: INTERCOM_APP_ID });
    };

    const showIntercom = () => {
        Intercom("show");
    };

    const shutdownIntercom = () => {
        Intercom("shutdown");
    };


    useEffect(() => {
        initIntercom();
    }, []);*/

    return (
        <>
            <IntercomProvider appId={INTERCOM_APP_ID} autoBoot={!!user} autoBootProps={{ userId: user?.user_id }}>
                <Router>
                    <Routes>
                        <Route path="/" element={<HomepageNew/>}/>
                        <Route path="/contact-us" element={<ContactUsPage/>}/>
                        <Route path="/about-us" element={<AboutUsPage/>}/>
                        <Route path="/signup" element={<SignupPage/>}/>
                        <Route path="/legal" element={<LegalPage/>}/>
                        <Route path="/legal-detail" element={<LegalDetailPage/>}/>
                        <Route path="*" element={<NotFound/>}/>
                        <Route path="/thank-you" element={<ThankYouPage/>}/>
                        <Route path="/router" element={<RouterPage/>}/>
                        <Route path="/usage-policy" element={<Usagepolicy/>}/>
                        <Route path="/data-protection" element={<Dataprotection/>}/>
                        <Route path="/privacy-policy" element={<Privacypolicy/>}/>
                        <Route path="/terms-of-services" element={<Termsofprivacy/>}/>
                        <Route path="/disclouser" element={<PrivacyDisclouser/>}/>
                        <Route path="/consent" element={<ParentalConsent/>}/>
                        <Route path="/student-privacy" element={<StudentPrivacy/>}/>
                        <Route path="/mark-usage" element={<MarkUsage/>}/>
                        <Route path="/guidelines" element={<UsageGuidelines/>}/>
                        <Route path="/processor" element={<SubProcessor/>}/>
                        <Route path="/brand" element={<Brand/>}/>
                        <Route path="/about-new" element={<AboutNew/>}/>
                        <Route path="/new-home" element={<NewHome/>}/>
                        <Route path="/demoaboutpage" element={<AboutDemoPageSideModals/>}/>
                        {/* <Route path="/ed-cloud" element={<EdCloud/>}/> */}
                        <Route path="/letter-Page" element={<LetterPage/>}/>
                        <Route path="/welcome-page" element={<Welcome/>}/>
                        <Route path="/thank-page" element={<ThanksPage/>}/>
                        <Route path="/Org-Create" element={<OrgCreate/>}/>
                        <Route path="/invite-page" element={<InvitedPage/>}/>
                        <Route path="/signup-welcome" element={<SignupWelcome/>}/>
                        <Route path="/verify-page" element={<VerifyPage/>}/>
                        <Route path="/member-page" element={<MemberPage/>}/>
                        <Route path="/organization-page" element={<OrganizationPage/>}/>

                        <Route path="/legal-dashboard" element={<LegalCenterDashboard />}>
                            <Route index element={<Termsofprivacy />} />
                        </Route>

                    </Routes>

                {/*    <div className={'inter-com-chat-wrapper'}>
                        <button className={'intercom-chat-btn'} onClick={showIntercom}>
                            <IoChatbubblesSharp />
                        </button>
                    </div>*/}

                </Router>
            </IntercomProvider>
        </>
    );
}

export default App;
