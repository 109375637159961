import React from 'react';

import termsServices from '../images/icons/terms-services-icon.svg'

export default function SidebarComponent() {
    return (
        <div id={'side-bar-main-wrapper'}>
            <div id="side-bar-wrapper">
                <ul className="side-menu-wrapper">
                    <li><a href="#"> <img src={termsServices} alt="termsServices"/><span>Terms of Service</span></a>
                    </li>
                    <li><a href="#"> <img src={termsServices} alt="termsServices"/><span>Privacy Policy </span></a></li>
                    <li><a href="#"> <img src={termsServices} alt="termsServices"/><span>Acceptable Usage Policy</span></a>
                    </li>
                    <li><a href="#"> <img src={termsServices} alt="termsServices"/><span>Data Protection Addendum</span></a>
                    </li>
                    <li><a href="#"> <img src={termsServices} alt="termsServices"/><span>Privacy Disclosure for Children (COPPA)</span></a>
                    </li>
                    <li><a href="#"> <img src={termsServices} alt="termsServices"/><span>Parental Consent</span></a>
                    </li>
                    <li><a href="#"> <img src={termsServices}
                                          alt="termsServices"/><span>Student Privacy Pledge</span></a></li>
                    <li><a href="#"> <img src={termsServices}
                                          alt="termsServices"/><span>Ilmiya's Mark Usage Terms</span></a></li>
                    <li><a href="#"> <img src={termsServices} alt="termsServices"/><span>AI Usage Guidelines</span></a>
                    </li>
                    <li><a href="#"> <img src={termsServices}
                                          alt="termsServices"/><span>Ilmiya Sub-Processors List</span></a></li>
                </ul>
              {/*  <div className="side-bar-toggle-wrapper">
                    <a href="#"></a>
                </div>*/}
            </div>
        </div>
    )
}
