import React, {useState} from 'react';
import { Outlet } from "react-router-dom";
import SecondaryHeader from "../components/SecondaryHeader";
import SecondaryFooter from "../components/SecondaryFooter";
import SidebarComponent from "../components/SidebarComponent";

export  default  function LegalCenterDashboard(){

    const [sidebar , setSidebar] = useState(true);
    const sidebarOpenHandler = () => {
        setSidebar(true)
    }
    const sidebarCloseHandler = () => {
        setSidebar(false)
    }

    return(
        <div className={'legal-center-detail-page'}>
            <SecondaryHeader />
            <div className="main-page-wrapper">
                <SidebarComponent  />
                <div className="inner-page-wrapper">
                    <Outlet />
                </div>
            </div>
            <SecondaryFooter />
        </div>
    )
}