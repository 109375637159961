import React from 'react'
import SecondaryFooter from "../components/SecondaryFooter";
import SecondaryHeader from "../components/SecondaryHeader";
import { Link } from "react-router-dom";
import ilmiyalegalimg from "../images/ilmiyalegalimg.svg"
import globeIcon from "../images/globeicon.svg";
import legalIcon from "../images/icons/legal-icon.svg"

const UsageGuidelines = () => {
  return (
    <>
       <div className="legal-details-page-wrappper">
                <SecondaryHeader />
                {/* <LegalTopbar /> */}
                <div className="page-breadcrumb">
                    <strong>
                        <Link className='breadcrumb-icon-text' to={"/legal"}> <img src={legalIcon} alt='legal-icon'/><strong> Legal Center</strong></Link>
                        / AI Usage Guidelines
                    </strong>
                </div>
                <div className="page-content-wrapper">
                    {/* <LegalSidebar /> */}
                    <div className="page-content">
                        <div className="container">
                        <div className="page-content-section">
                        <div className="top-image-wrapper d-flex justify-content-center">
                        <img src={ilmiyalegalimg} alt="ilmiya legal center img" className="ilmiya-legal-center-img" />
                        </div>
                            <div className="page-content-section">
                                <div className="page-content-container">
                                <div className="content-title">
                                    <h1>AI Usage Guidelines</h1>
                                </div>
                                <div className='content-wrapper'>
                                    <div className='description mt-0'>
                                        <p>Navigating the integration of AI in education is both exciting and challenging. This guide is designed to assist educators and administrators in effectively using<strong>  Ilmiya Language  Models</strong> (ILM) and the <strong>Ilmiya Platform</strong>. Whether you're new to AI or looking to deepen your engagement with these innovative tools, this guide will support you at every step.</p>
                                    </div>

<div className='description'>
    <h2>Getting Started with Responsible AI Use</h2>
    <p>Adopting AI technologies in education requires a solid understanding of their responsible use. <strong>Ilmiya</strong> supports educators by providing tools to assist in the classroom, helping you make informed decisions about AI integration.</p>
</div>
<div className='description'>
    <h2>Understanding AI's Capabilities and Limitations</h2>
    <p><strong>Ilmiya Language Models</strong> and the <strong>Ilmiya Platform</strong>  leverage advanced AI technologies to support educators. These models are based on complex machine learning architectures that predict the most probable responses to your inputs, which may result in outputs that are probabilistic and may not always be fully accurate. Some models are open-source, self-hosted, while others are third-party hosted models, allowing for flexibility in application and personalized AI experiences.</p>
    <p className='mt-3'>Because these models predict the next most probable word or text based on their training data, they are inherently prone to generating responses that may be incomplete, inaccurate, or unreliable. This can lead to content that seems plausible but may not fully represent the original information. For example, responses could misrepresent historical facts or generate conclusions that sound reasonable but are misleading or incomplete.</p>
    <p className='mt-3'>We strongly recommend that you verify the information generated by ILM tools and avoid using them in scenarios where precise, up-to-date information is crucial. This includes cases where the accuracy of facts, dates, or personal information is of utmost importance. Always review AI-generated responses before sharing them with students or colleagues.</p>
</div>
<div className='description'>
    <h2>Privacy Matters</h2>
    <p> <strong>lmiya</strong> is committed to safeguarding user privacy when utilizing AI-powered tools. <strong>Ilmiya Language Models  </strong> are powered by external AI partners, and while we implement protective measures to secure submitted data, these tools are not intended to process personally identifiable information (PII). Importantly, customer inputs and outputs are not stored or used to train the models. This ensures your data is kept private and secure throughout the process. We strongly advise users to refrain from submitting PII when interacting with ILM.</p>
    <p className='mt-3'>When using third-party hosted models, data is sent securely via SSL encryption and processed solely to deliver results back to users. The data you submit and the responses you receive are used solely to enhance your experience and are not shared between customers or used to train any models.</p>
</div>
<div className='description'>
    <h2>Keep Humans in the Loop</h2>
    <p> <strong>Ilmiya Platform</strong> is designed to augment your role as an educator, not replace it. While these tools can streamline certain tasks, they are not a substitute for your professional expertise and judgment. Relying solely on AI for student assessments or making critical decisions is not recommended. Human oversight is essential in ensuring AI-generated content is appropriate and accurate.</p>
</div>

<div className='description'>
    <h2>Adherence to School Policies</h2>
    <p>When utilizing <strong>Ilmiya</strong> within your school environment, it is imperative to follow your school's existing policies and guidelines regarding the use of AI tools. Ensuring that your use of <strong> Ilmiya</strong> complies with these policies is crucial to fostering a responsible and ethical use of AI in education.</p>
</div>
<div className='description'>
    <h2>Guidelines for Use and Conduct</h2>
    <p>AI-powered tools from Ilmiya are designed for individual educational use. We require all users to adhere to our <a href="" className='terms-link-text'> <img src={globeIcon} alt="globeIcon" /> Terms of Service</a> and OpenAI's Usage Policy, which prohibits illegal activities, harm to others, or generating harmful, discriminatory, or offensive content. These guidelines help maintain a positive and responsible approach to AI in education.</p>
</div>

<div className='description'>
    <h2>Creating and Sharing AI-Generated Content</h2>
    <p>When using <strong>Ilmiya</strong> to create content, it is your responsibility to ensure you have the appropriate rights to any materials you submit ("Input") to ILM and verify the accuracy of any content generated ("Output"). If you share AI-generated materials, you must comply with our terms, including disclosing the role of AI in its creation, as well as adhering to OpenAI’s Sharing and Publication Policy.</p>
</div>
<div className='description'>
    <h2>Ilmiya in the Classroom</h2>
    <p>If your school incorporates <strong>Ilmiya Language Models</strong> with students, you play a crucial role in teaching them the responsible use of AI. This includes guiding students to understand the limitations of AI technology and discouraging them from sharing personal information while interacting with the system.</p>
</div>
<div className='description'>
    <h2>Additional Aspects to Consider</h2>
    <p className='mb-3'>To ensure a more comprehensive and responsible use of AI, educators and administrators should consider the following:</p>

    <ol>
        <li>
            <p><strong>Ethical Use of AI</strong>: It is critical to ensure AI usage promotes fairness, diversity, and inclusivity. This includes avoiding biases in content generated by AI models and fostering an environment of equity.</p>
        </li>
        <li>
        <p><strong>Continuous Professional Development</strong>: Educators should engage in ongoing training to stay informed about updates to AI tools, best practices for use in classrooms, and evolving guidelines for AI in education.</p>
        </li>
        <li>
        <p><strong>Data Security and Compliance: Ilmiya</strong> ensures compliance with all relevant data protection laws, such as GDPR, FERPA, and COPPA. Educators and administrators should also be familiar with these laws to maintain compliance when using AI-powered tools.</p>
        </li>
        <li>
        <p><strong>Feedback Mechanisms</strong>: Educators should have the ability to provide feedback on ILM tools to ensure continuous improvement of the models and their application in education.</p>
        </li>
    </ol>

</div>

<div className='description'>
    <h2>Onward!</h2>
    <p>Our mission is to promote a thoughtful and informed approach to using AI tools in education. By following these guidelines, educators and administrators can leverage AI to enrich learning while upholding ethical standards and ensuring privacy. Together, we can embrace the transformative potential of AI in education.</p>
</div>




                                </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SecondaryFooter />
    </>
  )
}

export default UsageGuidelines
