import Dropdown from "react-bootstrap/Dropdown";
import logo from "../images/icons/logo.svg";
import copy from "../images/icons/copy-icon.svg";
import brandIcon from "../images/brandIcon.svg";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
export default function BrandDropdown() {
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const handleLogoClick = (e) => {
    // Left-click handler
    if (e.button === 0) {
      navigate("/");
    }
    setShowDropdown(false); 

  };

  const handleLogoContextMenu = (e) => {
    e.preventDefault(); 
    setShowDropdown(!showDropdown); 
  };

  const handleOutsideClick = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setShowDropdown(false); 
    }
  };

  const handleItemClick = () => {
    setShowDropdown(false); 
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);


  function handleClick(e){
    setShowDropdown(!showDropdown);  // Toggle the dropdown menu on click
    e.preventDefault();  // Prevent the default link behavior (page reload) when clicked on the logo image
  }
  return (
    <div className="brand-dropdown-wrapper" ref={dropdownRef}>
      <Dropdown className="brand-dropdown" show={showDropdown}>
        <Dropdown.Toggle as="div" className="custom-toggle">
          <img
            src={logo}
            alt="logo"
            onClick={handleLogoClick}
            onContextMenu={handleLogoContextMenu}
            style={{ cursor: "pointer" }}
            className="logo-first-img"
          />
          <img
            src={logo}
            alt="logo"
            onClick={handleClick}
            style={{ cursor: "pointer" }}
            className="logo-second-img"
          />
        </Dropdown.Toggle>

        <Dropdown.Menu className="logo-dropdown">
          <Dropdown.Item onClick={handleItemClick}>
            <div className="single-item">
              <div className="icon">
                <img src={copy} alt="icon" />
              </div>
              <Link to={"/"}>Copy</Link>
            </div>
          </Dropdown.Item>
          <Dropdown.Item onClick={handleItemClick}>
            <div className="single-item">
              <div className="icon">
                <img src={brandIcon} alt="icon" />
              </div>
              <Link to={"/brand"}>Brand</Link>
            </div>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
