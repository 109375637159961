import React from 'react'
import ilmiyalogo from '../images/icons/Ilmiya Logo.png';
import logo from '../images/icons/Group 1413371562.png';
import blacklogo from '../images/icons/black logo ilmiya.png';
import downloadIcon from '../images/icons/download-icon.svg';
import bluelogo from '../images/icons/blue logo imiya.svg';
import SecondaryHeader from '../components/SecondaryHeader';
import SecondaryFooter from '../components/SecondaryFooter';

const Brand = () => {
    return (
        <>
            <SecondaryHeader/>
            <div className='brand-page-wrapper'>
                <div className='container'>
                    <div className="row">
                        <div className="col-md-12">
                            <div className='brand-heading'>
                                <h1>Brand <span className={'gradient'}>Assets</span></h1>
                            </div>
                            <div className='brand-content'>
                                <p>Focus on what you do best—changing students' lives. Go borderless!Expand your impact <br />
                                    with Ilmiya’s global infrastructure, designed to help you reach.</p>
                            </div>
                        </div>
                    </div>
                    <div className='brand-card-wrapper'>
                        <div className="row">
                            <div className='mb-3 mb-md-4 col-12 col-md-6'>
                                <div className="brand-card">
                                    <img src={ilmiyalogo} alt="logo"/>
                                    <div className="download-btn">
                                        <a href="#">
                                            <img src={downloadIcon} alt="downloadIcon"/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className='mb-3 mb-md-4 col-12 col-md-6'>
                                <div className='brand-card'>
                                    <img src={bluelogo} alt="logo"/>
                                    <div className="download-btn">
                                        <a href="#">
                                            <img src={downloadIcon} alt="downloadIcon"/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className='mb-3 mb-md-4 col-12 col-md-6'>
                                <div className='brand-card'>
                                    <img src={logo} alt="logo"/>
                                    <div className="download-btn">
                                        <a href="#">
                                            <img src={downloadIcon} alt="downloadIcon"/>
                                        </a>
                                    </div>
                                </div>

                            </div>
                            <div className='mb-3 mb-md-4 col-12 col-md-6'>

                                <div className='brand-card'>
                                    <img src={blacklogo} alt="logo"/>
                                    <div className="download-btn">
                                        <a href="#">
                                            <img src={downloadIcon} alt="downloadIcon"/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SecondaryFooter/>
        </>
    )
}

export default Brand
