import SecondaryHeader from "../components/SecondaryHeader";
import ilmiyaIcon from "../images/icons/ilmiya-icon.svg";
import iPad from "../images/iPad.png";
import LegalTopbar from "../components/LegalTopbar";
import { Link } from "react-router-dom";
import LegalSidebar from "../components/LegalDetailSidebar";
import SecondaryFooter from "../components/SecondaryFooter";
import { HiOutlineKey } from "react-icons/hi2";
import { FiAlertCircle } from "react-icons/fi";
import ilmiyalegalimg from "../images/ilmiyalegalimg.svg"
import legalIcon from "../images/icons/legal-icon.svg"

export default function Usagepolicy() {
  return (
    <div className="legal-details-page-wrappper">
      <SecondaryHeader />
      {/* <LegalTopbar /> */}
      <div className="page-breadcrumb">
        <strong>
          <Link className='breadcrumb-icon-text' to={"/legal"}> <img src={legalIcon} alt='legal-icon'/><strong> Legal Center</strong></Link>/ Acceptable Usage Policy
        </strong>
      </div>
      <div className="page-content-wrapper">
        {/* <LegalSidebar /> */}
        <div className="page-content">
          <div className="container">
            <div className="page-content-section page-protect-section">
                        <div className="top-image-wrapper d-flex justify-content-center">
                        <img src={ilmiyalegalimg} alt="ilmiya legal center img" className="ilmiya-legal-center-img" />
                        </div>
              <div className="page-content-container">
                <div className="content-title">
                  <h1>Acceptable Usage Policy</h1>
                </div>
                <div className="content-wrapper">
                  <div className="description mt-2">
                    <p>
                      The Acceptable Use Policy applies to everyone, regardless
                      of location or the type of content they are producing. It
                      is designed to set clear standards for acceptable behavior
                      on our platform, covering a wide range of content and
                      activities. If someone violates this policy or any of our
                      other policies, the consequences will depend on the
                      severity of the violation and the person's history on our
                      platform. In situations where there is a risk of physical
                      harm or a threat to public safety, we may also notify law
                      enforcement. We ask that all users of our product follow
                      these guidelines to ensure a positive and respectful
                      environment for everyone.
                    </p>
                  </div>
                  {/* <div className="suspend-section">
                  </div> */}
                  <div className="description">
                    <h2>Legal Compliance</h2>
                    <p>
                      You must use the Product in compliance with, and only as
                      permitted by, applicable law. The use of our Product in
                      conjunction with other tools or resources in furtherance
                      of any of the unacceptable uses we describe is also
                      prohibited.
                    </p>
                    {/* <p>
                    As part of becoming a Zendesk developer partner, Zendesk
                    sponsors an instance for up to 5 agents that you can use for
                    developing, demoing, and troubleshooting your app or
                    integration.
                  </p>
                  <p>
                    Unlike a trial account, a sponsored account does not expire
                    after 14 days.
                  </p>
                  <p>
                    By default, all Marketplace partners with a sponsored Support
                    account receive the Suite Enterprise plan. A sponsored Sell
                    account includes all features on the Sell Elite plan except
                    the Firehouse, Sync, and Search APIs.
                  </p>
                  <p>
                    <span>Note: </span>Zendesk will suspend any sponsored Support
                    account used to provide actual support.
                  </p> */}
                  </div>

                  {/* <div className="suspend-section">
                  </div> */}
                  <div className="description">
                    <h2>Your Responsibilities</h2>
                    <p className="mb-2">
                      You are responsible for your conduct, Content, and
                      communications with others while using the Product. You
                      must comply with the following requirements when using the
                      Product. If we become aware of any conduct or Content that
                      falls outside the bounds of what is acceptable under this
                      policy, we will take all appropriate action, including
                      removing it and reporting it, as well as suspending or
                      terminating your account. We also take steps to prevent
                      uses of our Product that are contrary to the spirit of
                      this policy.
                    </p>
                    <p className="mb-2">
                      Unless authorized by Ilmiya in writing, you may not probe,
                      scan, or test the vulnerability or security of the Product
                      or any Ilmiya system or network.
                    </p>
                    <p className="mb-2">
                      Unless authorized by Ilmiya in writing, you may not use
                      any manual or automated system or software to extract or
                      scrape data from websites or other interfaces through
                      which we make our Product available.
                    </p>
                    <p className="mb-2">
                      You may not use the Product to commit an unlawful
                      activity; or use the Product for activities where use or
                      failure of the Product could lead to physical damage,
                      death, mental harm, or personal injury.
                    </p>
                    <p className="mb-2">
                      You may not purchase, use, or access the Product for the
                      purpose of building a product or service that is
                      competitive to Ilmiya or for any other competitive
                      purposes.
                    </p>
                    <p className="mb-2">
                      You may not misuse our Product by interfering with its
                      normal operation or attempting to access it using a method
                      other than through the interfaces and instructions that we
                      provide.
                    </p>
                    <p className="mt-2">
                      You may not circumvent or attempt to circumvent any
                      limitations that Ilmiya imposes on your account (such as
                      by opening up a new account to create or distribute
                      Content that we have closed for a violation of our terms
                      or policies).
                    </p>
                    <p className="mt-2">
                      You may not deny others access to, or reverse engineer, the
                      Product, or assist anyone else to do so, to the extent
                      such restriction is permitted by law.
                    </p>
                    <p className="mt-2">
                      You may not store or transmit any viruses, malware, or other
                      types of malicious software, or links to such software,
                      through the Product.
                    </p>
                    <p className="mt-2">
                      You may not use the Product to infringe the intellectual
                      property rights of others.
                    </p>
                    <p className="mt-2">
                      Unless authorized by Ilmiya in writing, you may not resell
                      or lease the Product.
                    </p>
                    <p className="mt-2">
                      If your use of the Product requires you to comply with
                      industry-specific regulations applicable to such use, you
                      will be solely responsible for such compliance, unless
                      Ilmiya has agreed with you in writing otherwise. You may
                      not use the Product in a way that would subject Ilmiya to
                      those industry-specific regulations without obtaining
                      Ilmiya’s prior written agreement.
                    </p>
                    <p className="mt-2">
                      You may not post or upload any Content that contains
                      personally identifiable information, sensitive personal
                      information, or confidential information, such as credit
                      card numbers, confidential national ID numbers, or account
                      passwords, unless you have consent from the person to whom
                      the information belongs or who is otherwise authorized to
                      provide such consent. You may not use the Product to
                      collect, store, or process any protected health
                      information subject to the Health Insurance Portability
                      and Accountability Act (HIPAA), any applicable health
                      privacy regulation, or any other applicable law governing
                      the processing, use, or disclosure of health information.
                    </p>
                    <p className="mt-2">
                      You may not register accounts by “bots” or other automated
                      methods.
                    </p>
                    <p className="mt-2">
                      We may offer content such as images or video that are
                      provided by third parties. You may use that material
                      solely in your content. Ilmiya may modify or revoke that
                      permission at any time at our sole discretion. In using
                      such material, you may not imply that your content is
                      affiliated with or run or endorsed by any company,
                      product, brand, or service depicted in that material
                      unless you have obtained their permission.
                    </p>
                    <p className="mb-2 mt-2">
                      You may not engage in abusive or excessive usage of the
                      Product, which is usage significantly in excess of average
                      usage patterns that adversely affects the speed,
                      responsiveness, stability, availability, or functionality
                    </p>

                    {/* <p>
                    <span>Note: </span>Zendesk will suspend any sponsored Support
                    account used to provide actual support.
                  </p> */}
                  </div>
                  <div className="trial-account">
                    <div className="description">
                    <h4>Prohibited Businesses</h4>
                      <p className="mt-2">
                        Using Ilmiya's services for any illegal, fake, or unfair
                        activities or products is strictly prohibited. This
                        includes, but is not limited to, the following:
                      </p>
                    </div>
                    <ul>
                      <li className="mt-1 mb-0">
                        <span>
                          Illegal drugs and drug-making equipment, including
                          substances designed to mimic illegal drugs and
                          equipment designed for making or using drugs.
                        </span>
                      </li>
                      <li className="mt-1 mb-0">
                        <span>
                          Fake identification or reference services, including
                          any business or organization that provides fake
                          references or ID documents.
                        </span>
                      </li>
                      <li className="mt-1 mb-0">
                        <span>
                          Telecommunications manipulation equipment, such as
                          devices that are used to interfere with or disrupt
                          communication systems.
                        </span>
                      </li>
                      <li className="mt-1 mb-0">
                        <span>
                          Products or services that infringe on intellectual
                          property rights, including the unauthorized sale of
                          licensed materials such as music, movies, or software,
                          counterfeit goods, illegally imported or exported
                          products, unauthorized sale of brand name or designer
                          products or services, or any other products or
                          services that directly infringe or facilitate
                          infringement upon the trademark, patent, copyright,
                          trade secrets, proprietary, or privacy rights of any
                          third party.
                        </span>
                      </li>
                      <li className="mt-1 mb-0">
                        <span>
                          Pyramid schemes, 'get rich quick' schemes, or any
                          other businesses that promise high rewards for little
                          effort or up front work, make outrageous claims, use
                          deceptive testimonials, use high-pressure upselling,
                          or use fake testimonials.
                        </span>
                      </li>
                      <li className="mt-1 mb-0">
                        <span>
                          No value added services, including the sale or resale
                          of a service without added benefit to the buyer or the
                          resale of government offerings without authorization
                          or added value.
                        </span>
                      </li>
                      <li className="mt-1 mb-0">
                        <span>
                          Predatory or deceptive services, such as mortgage
                          consulting, lending, credit repair, or counseling
                          services, investment opportunities with no or low
                          money down, or remote technical support.
                        </span>
                      </li>
                      <li className="mt-1 mb-0">
                        <span>
                          Adult content and services, including pornography,
                          prostitution, escorts, pay-per-view services, sexual
                          massages, and adult live chat features.
                        </span>
                      </li>
                      <li className="mt-1 mb-0">
                        <span>
                          Firearms, explosives and dangerous materials, guns,
                          gunpowders, ammunitions, weapons, fireworks and other
                          explosives.
                        </span>
                      </li>
                      <li className="mt-1 mb-0">
                        <span>
                          Unauthorized sale of brand name or designer products
                          or services: This category includes any business or
                          service that sells unauthorized copies of brand name
                          or designer products or services.
                        </span>
                      </li>
                      <li className="mt-1 mb-0">
                        <span>
                          Sales of online traffic or engagement: This category
                          includes any business or service that sells online
                          traffic or engagement, such as by using bots or other
                          automated methods to artificially inflate website
                          traffic or social media engagement.
                        </span>
                      </li>
                      <li className="mt-1 mb-0">
                        <span>
                          Negative response marketing and telemarketing: This
                          category includes any business or service that uses
                          negative response marketing tactics, such as sending
                          unsolicited messages or making unsolicited phone calls
                          to consumers who have not expressed an interest in the
                          products or services being offered.
                        </span>
                      </li>
                    </ul>
                  </div>

                  <div className="trial-account">
                    <h4>Phishing and Security</h4>
                    <div className="description mt-0">
                      <p className="mt-2">
                        We prioritize the security of all our users and take
                        measures to prevent fraudulent or malicious content from
                        misleading recipients. We will suspend any use of our
                        product that:
                      </p>
                    </div>
                    <ul className="mt-0">
                      <li className="mt-1 mb-0">
                        <span>
                          Attempts to collect sensitive information such as
                          social security numbers, credit card numbers (except
                          for authorized payment processing), passwords, or
                          similar information.
                        </span>
                      </li>
                      <li className="mt-1 mb-0">
                        <span>
                          Publishes sensitive identifying information without
                          consent.
                        </span>
                      </li>
                      <li className="mt-1 mb-0">
                        <span>
                          Is intended to deceive or mislead recipients,
                          including by linking to websites with malicious
                          software.
                        </span>
                      </li>
                      <li className="mt-1 mb-0">
                        <span>
                          Knowingly and artificially improves a website or
                          webpage's search engine ranking.
                        </span>
                      </li>
                    </ul>
                  </div>

                  <div className="trial-account">
                    <div className="description">
                    <h4>Privacy and Impersonation</h4>
                      <p className="mt-2">
                        Your users provide information with the expectation that
                        their information will be handled respectfully and not
                        abused. Accordingly, you are responsible for complying
                        with all applicable data protection laws and regulations
                        with respect to any data that you submit to or collect
                        through our Product. For more information, please review
                        our{" "}
                        <a href="#">
                          {" "}
                          <span className="bg-blue" style={{color:'#0d3c82'}}>
                            <HiOutlineKey />
                            Privacy Policy
                          </span>
                        </a>{" "}
                        and{" "}
                        <a href="#">
                          {" "}
                          <span className="bg-blue" style={{color:'#0d3c82'}}>
                            <FiAlertCircle />
                            Terms of Service
                          </span>
                        </a>
                        .
                      </p>
                      <p className="mt-2">
                        We encourage you to disclose your privacy practices when
                        you use the Product and, if you do, we require you to
                        act in accordance with those practices.
                      </p>
                      <p className="mt-2">
                        You may not claim that any use of our Product is
                        anonymous when it is not.
                      </p>
                      <p className="mt-2">
                        You may not impersonate others when using the Product or
                        collecting information.
                      </p>
                    </div>
                  </div>

                  <div className="trial-account">
                    <div className="description">
                    <h4>Cybersquatting</h4>
                      <p>
                        Cybersquatting, also known as domain squatting, is the
                        practice of registering, trafficking in, or using a
                        domain name with the intention of profiting from the
                        goodwill of someone else's trademark. It often involves
                        registering a domain name that is similar to a
                        well-known brand or trademark in order to divert traffic
                        from the legitimate website to the cybersquatter's own
                        site. This can be done with the intention of selling the
                        domain back to the trademark owner for a profit, or with
                        the intention of using the domain to sell counterfeit
                        goods or other illegal products. Cybersquatting is
                        considered to be unethical and is often illegal, as it
                        can confuse consumers and cause harm to the reputation
                        of the trademark owner. If you purchase a Ilmiya product
                        account in someone else’s name and then try to sell that
                        account to them, you are cybersquatting. Cybersquatting
                        accounts are subject to immediate cancelation.
                      </p>
                    </div>
                  </div>

                  <div className="trial-account">
                    <div className="description">
                    <h4>CAN-SPAM</h4>
                      <p>
                        We respect the personally identifiable information of
                        our users, including their names, email addresses,
                        mobile numbers, and we expect that our users who collect
                        such information will also treat it with respect.
                      </p>
                    </div>
                    <ul className="mt-0">
                      <li className="mt-1 mb-0">
                        <span>
                          Don’t use false or misleading header information. Your
                          “From,” “To,” “Reply-To,” and routing information –
                          including the originating domain name and email
                          address – must be accurate and identify the person or
                          business who initiated the message.
                        </span>
                      </li>
                      <li className="mt-1 mb-0">
                        <span>
                          Don’t use deceptive subject lines. The subject line
                          must accurately reflect the content of the message.
                        </span>
                      </li>
                      <li className="mt-1 mb-0">
                        <span>
                          Identify the message as an ad. The law gives you a lot
                          of leeway in how to do this, but you must disclose
                          clearly and conspicuously that your message is an
                          advertisement.
                        </span>
                      </li>
                      <li className="mt-1 mb-0">
                        <span>
                          Tell recipients where you’re located. Your message
                          must include your valid physical postal address. This
                          can be your current street address, a post office box
                          you’ve registered with the U.S. Postal Service, or a
                          private mailbox you’ve registered with a commercial
                          mail receiving agency established under Postal Service
                          regulations.
                        </span>
                      </li>
                      <li className="mt-1 mb-0">
                        <span>
                          Tell recipients how to opt out of receiving future
                          email from you. Your message must include a clear and
                          conspicuous explanation of how the recipient can opt
                          out of getting email from you in the future. Craft the
                          notice in a way that’s easy for an ordinary person to
                          recognize, read, and understand. Creative use of type
                          size, color, and location can improve clarity. Give a
                          return email address or another easy Internet-based
                          way to allow people to communicate their choice to
                          you. You may create a menu to allow a recipient to opt
                          out of certain types of messages, but you must include
                          the option to stop all commercial messages from you.
                          Make sure your spam filter doesn’t block these opt-out
                          requests.
                        </span>
                      </li>
                      <li className="mt-1 mb-0">
                        <span>
                          Honor opt-out requests promptly. Any opt-out mechanism
                          you offer must be able to process opt-out requests for
                          at least 30 days after you send your message. You must
                          honor a recipient’s opt-out request within 10 business
                          days. You can’t charge a fee, require the recipient to
                          give you any personally identifying information beyond
                          an email address, or make the recipient take any step
                          other than sending a reply email or visiting a single
                          page on an Internet website as a condition for
                          honoring an opt-out request. Once people have told you
                          they don’t want to receive more messages from you, you
                          can’t sell or transfer their email addresses, even in
                          the form of a mailing list. The only exception is that
                          you may transfer the addresses to a company you’ve
                          hired to help you comply with the CAN-SPAM Act.
                        </span>
                      </li>
                      <li className="mt-1 mb-0">
                        <span>
                          Monitor what others are doing on your behalf. The law
                          makes clear that even if you hire another company to
                          handle your email marketing, you can’t contract away
                          your legal responsibility to comply with the law. Both
                          the company whose product is promoted in the message
                          and the company that actually sends the message may be
                          held legally responsible.
                        </span>
                      </li>
                    </ul>
                  </div>

                  <div className="trial-account">
                    <div className="description">
                    <h4>Misinformation and Disinformation</h4>
                      <p>
                        We remove content that is determined to be
                        misinformation or disinformation and may report such
                        information to law enforcement authorities if there is a
                        genuine risk of harm or threat to public safety. This
                        includes, but is not limited to:
                      </p>
                    </div>
                    <ul className="mt-2">
                      <li className="mt-1 mb-0">
                        <span>
                          Spreading medical misinformation that goes against the
                          guidance of government health authorities, using our
                          Product. Medical and scientific claims must be
                          supported by evidence.
                        </span>
                      </li>
                      <li className="mt-1 mb-0">
                        <span>
                          Using fabricated or manipulated content that may cause
                          harm to public safety and that would likely mislead
                          the average person into believing it is authentic.
                        </span>
                      </li>
                      </ul>
                    
                      <div className="description">
                        <p>
                          We also remove content and may report information
                          related to that content to law enforcement authorities
                          if we believe there is a genuine risk of harm or
                          threat to public safety and health. This includes:
                        </p>
                      </div>
                      <ul>
                      <li className="mt-1 mb-0">
                        <span>
                          Using our Product to threaten or attack others, or to
                          organize or incite violence, harassment, or property
                          damage.
                        </span>
                      </li>
                      <li className="mt-1 mb-0">
                        <span>
                          Using our Product for hate speech, or to promote,
                          condone, or fund such acts. Hate speech includes
                          attacking or advocating for the abuse or exclusion of
                          people based on their ethnicity, national origin,
                          political or religious affiliations, gender, sexual
                          orientation, genetic predisposition, disability,
                          medical or physical condition, veteran status, or any
                          other protected classes under applicable law. Our
                          Product may not be used to promote or support
                          organizations, platforms, or people that promote or
                          condone hate or threaten or condone violence to
                          further a cause.
                        </span>
                      </li>
                      <li className="mt-1 mb-0">
                        <span>
                          Promoting or glorifying self-harm on our Product.
                        </span>
                      </li>
                    </ul>
                  </div>

                  <div className="trial-account">
                    <div className="description">
                    <h4>Illegal Activity</h4>
                      <p>
                        When you operate or do business in a particular
                        jurisdiction, you are subject to the laws of that
                        jurisdiction. This means that you must comply with all
                        laws and regulations that apply to your business,
                        including any laws related to the goods or services you
                        offer, or the content you post or upload. If you offer
                        goods or services, or post or upload content that goes
                        against these laws or helps facilitate activities that
                        go against these laws, you may be violating the terms of
                        use of the platform and could face legal consequences.
                        It is important to ensure that you are aware of and
                        comply with all applicable laws and regulations in the
                        jurisdiction in which you operate or do business. This
                        includes:
                      </p>
                    </div>
                    <ul>
                      <li className="mt-1 mb-0">
                        <span>
                          You are not allowed to offer goods or services, or
                          post or upload content that suggests or promotes
                          support or funding of, or membership in, a terrorist
                          organization.
                        </span>
                      </li>
                      <li className="mt-1 mb-0">
                        <span>
                          You are not allowed to offer goods or services, or
                          post or upload content that exploits or abuses
                          children, such as images or depictions of child abuse
                          or sexual abuse, or content that presents children in
                          a sexual manner, or requests personally identifiable
                          information from children. Child exploitation and
                          abuse is a serious issue and it is important to ensure
                          that children are protected from harm. When you offer
                          goods or services, or post or upload content, you must
                          not engage in any activity that exploits or abuses
                          children. This includes, but is not limited to,
                          sharing images or depictions of child abuse or sexual
                          abuse, presenting children in a sexual manner, or
                          asking for personally identifiable information from
                          children. Children are particularly vulnerable and it
                          is important to respect their rights and protect them
                          from harm. It is important to be aware of and adhere
                          to laws and regulations that protect children from
                          exploitation and abuse.
                        </span>
                      </li>
                      <li className="mt-1 mb-0">
                        <span>
                          When you offer goods or services, or post or upload
                          content, you must not include anything that is
                          defamatory, obscene, indecent, offensive, abusive, or
                          otherwise objectionable. Defamatory content is content
                          that harms the reputation of an individual or
                          organization by making false or malicious statements.
                          Obscene content is content that offends against moral
                          standards of the community and is considered to be
                          sexually explicit or deviant. Indecent content is
                          content that is considered to be inappropriate or
                          offensive, but not necessarily legally obscene.
                          Offensive content is content that is likely to cause
                          offense or upset to the viewer or reader. Abusive
                          content is content that is intended to harm,
                          intimidate, or harass another person or group.
                          Objectionable content is content that is generally
                          unpleasant or disturbing. In addition, you must not
                          include pornographic or gratuitously violent content
                          in your offerings or posts. Pornographic content is
                          content that is sexually explicit or intended to
                          arouse sexual desire. Gratuitously violent content is
                          content that is excessively or unnecessarily violent
                          or graphic.
                        </span>
                      </li>
                      <li className="mt-1 mb-0">
                        <span>
                          When you use our product, you must not engage in
                          activities that deceive, misrepresent, or defraud or
                          exploit others for financial gain. Deception is the
                          act of intentionally misleading someone through false
                          or misleading statements or actions. Misrepresentation
                          is the act of giving a false or misleading impression.
                          Fraud is the act of deceiving or misrepresenting in
                          order to gain something by dishonest means.
                          Exploitation is the act of taking advantage of someone
                          for personal or financial gain. You must also not use
                          our product to provide instruction on, engage in,
                          coordinate, encourage, or promote activities that
                          deceive, misrepresent, defraud, or exploit others for
                          financial gain. This includes any content that is
                          related to such activities. It is important to be
                          honest and fair in your dealings with others and to
                          respect the rights and interests of others. It is also
                          important to adhere to laws and regulations that
                          prohibit deception, misrepresentation, fraud, and
                          exploitation.
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="trial-account">
                    <div className="description">
                    <h4>Intellectual Property Infringement</h4>
                      <p>
                        ll users of our product must respect the intellectual
                        property rights of others. This means that you must have
                        the necessary rights to any content that you use or
                        include through the use of our product. It is important
                        to respect the intellectual property rights of others
                        and to ensure that you have the necessary permissions or
                        licenses to use any content that you include in your
                        offerings or posts. Infringing on the intellectual
                        property rights of others can lead to legal consequences
                        and it is important to be aware of and comply with
                        applicable laws and regulations related to intellectual
                        property.
                      </p>
                      <p className="mt-3">
                        We reserve the right to remove any content and suspend
                        or terminate your account or access to our product at
                        any time and without notice if you engage in any
                        activities that violate this policy, whether these
                        activities take place within or outside of your use of
                        our product. This includes any activities that
                        contravene the terms and conditions outlined in this
                        policy. It is important to adhere to this policy and to
                        refrain from engaging in any activities that violate its
                        terms. Failure to do so may result in the removal of
                        your content and the suspension or termination of your
                        account or access to our product
                      </p>
                      <p className="mt-3">
                        We have the right, but not the obligation, to monitor or
                        investigate any Content and your use of the Product at
                        any time for compliance with this Policy and the TOS or
                        any other agreement between you and Ilmiya governing
                        your use of the Product. Our determination of whether a
                        violation of any of these terms has occurred will be
                        final and binding and any action taken with respect to
                        enforcing this policy or any other terms, including
                        taking no action at all, will be at our sole discretion.
                      </p>
                      <p className="mt-3">
                        Ilmiya may modify this Policy at any time by posting a
                        revised version at{" "}
                        <a href="#">
                          <span>https://ilmiya.com/legal/aup.</span>
                        </a>{" "}
                        By continuing to use the Product or access your account
                        after a revised version of this Policy has been posted,
                        you agree to comply with the latest version.
                      </p>
                      <p className="mt-3">
                        If you identify content that you believe is in violation
                        of this policy, you may file an abuse report at{" "}
                        <a href="#">
                          <span>support@ilmiya.com</span>
                        </a>
                        . Please include the URL of the website at issue.
                      </p>
                      <p className="mt-3">
                        Indemnity. You agree to indemnify and hold Ilmiya
                        harmless from any loss, liability claim or demand,
                        including reasonable legal expenses, made by any third
                        party due to or arising out of your use of the Platform
                        in violation of this Policy.
                      </p>
                    </div>
                  </div>
                  {/* <div className="page-banner">
                  <img src={iPad} alt="image" />
                </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SecondaryFooter />
    </div>
  );
}
