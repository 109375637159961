import React from "react";
import SecondaryFooter from "../components/SecondaryFooter";
import SecondaryHeader from "../components/SecondaryHeader";
import { Link } from "react-router-dom";
import { HiOutlineKey } from "react-icons/hi2";
import { FiAlertCircle } from "react-icons/fi";
import ilmiyalegalimg from "../images/ilmiyalegalimg.svg"
import legalIcon from "../images/icons/legal-icon.svg"

const PrivacyDisclouser = () => {
  return (
    <>
      <div className="legal-details-page-wrappper">
        <SecondaryHeader />
        {/* <LegalTopbar /> */}
        <div className="page-breadcrumb">
          <strong>
            <Link className='breadcrumb-icon-text' to={"/legal"}> <img src={legalIcon} alt='legal-icon'/> <strong> Legal Center</strong></Link>/ Privacy Disclosure for Children (COPPA)
          </strong>
        </div>
        <div className="page-content-wrapper">
          {/* <LegalSidebar /> */}
          <div className="page-content">
            <div className="container">
              <div className="page-content-section page-protect-section">
                        <div className="top-image-wrapper d-flex justify-content-center">
                        <img src={ilmiyalegalimg} alt="ilmiya legal center img" className="ilmiya-legal-center-img" />
                        </div>
               <div className="page-content-container">
               <div className="content-title">
                  <h1>Privacy Disclosure for Children (COPPA) </h1>
                </div>

                <div className="content-wrapper">
                  <div className="description">
                    <p>
                      Ilmiya, Inc. (“Ilmiya”) values the privacy of children and
                      of all of its users. This Child Privacy Statement is
                      designed to comply with the Children’s Online Privacy
                      Protection Act (“COPPA”) and contains important
                      information about how we collect, use, and disclose the
                      personal information we collect from or about children
                      under thirteen (13) years old who use our website, (the
                      “Site”) and the services provided on our Site (the
                      “Services”).
                    </p>

                    <p className="mt-2">
                      Ilmiya will handle children’s personal information as
                      described in this Child Privacy Statement. Any personal
                      information we collect about other users (e.g., teens and
                      adults) will be treated in accordance with the Ilmiya
                      Privacy Statement (“Privacy Statement”). The use of our
                      Site and any dispute over privacy, is subject to this
                      Child Privacy Statement, the Privacy Statement, and our
                      Terms of Service, including its applicable limitations on
                      damages and the resolution of disputes. Our Terms of
                      Service and Privacy Statement are incorporated by
                      reference into this Child Privacy Statement.
                    </p>
                  </div>

                  <div className="description">
                    <h2>1. Who Will Collect Information About Children?</h2>
                    <p>
                      Ilmiya operates the Site and Services and will collect
                      children’s personal information as described in this COPPA
                      Policy and our <a href="#"> <span className="bg-blue" style={{color:'#0d3c82', fontWeight:'500'}}><HiOutlineKey />
                      Privacy Policy</span></a>. We can be
                      contacted at:
                    </p>
                    <p className="mt-2">
                      Email: <a href="#"><span style={{fontWeight:'500'}}>support@ilmiya.com</span></a>
                    </p>
                    <p className="mt-2">
                      Even if Ilmiya Services are offered in conjunction with an
                      outside partner, the partner will not receive any
                      individual personal information from users of the Site,
                      including children. Ilmiya may provide partners with
                      aggregate or de-identified information about users.
                    </p>
                  </div>
                  <div className="description">
                    <h2>2. How Information is Collected?</h2>
                    <p>
                      We may collect information about children directly from
                      the child’s parent or guardian, as well as automatically
                      through a child’s use of our Site and Services. We will
                      not require a child to disclose more information than is
                      reasonably necessary to use our Services.
                    </p>
                  </div>
                  <div className="description">
                    <h2>3. Information We Collect Directly</h2>
                    <p>
                      From children under 13, we only use child’s name, and
                      email address to provide the Services. We will not contact
                      a child using his or her email address or username.
                      Parents may ask us to stop collecting personal information
                      from their child by emailing us at :{" "}
                      <span>
                      <a href="#"><span style={{fontWeight:'500'}}>support@ilmiya.com</span></a>
                      </span>
                      , however, in such cases the child will not be able to
                      utilize the Services. If a parent directs us to stop
                      collecting and using a child’s personal information, we
                      must disable his or her use of our Services to ensure that
                      no information is collected.
                    </p>
                  </div>

                  <div className="description">
                    <h2>4. Information We Collect Automatically</h2>
                    <p>
                      We may automatically collect the following information
                      about a child’s use of our Site through cookies and other
                      technologies: domain name; browser type and operating
                      system; web pages viewed; links clicked; the length of
                      time spent on our Site; Google Analytics statistics; the
                      referring URL, or the webpage that led the child to our
                      Site; language information; device name and model;
                      operating system type, name, and version; and activities
                      within our Site. We may also collect IP address, device
                      identifier or a similar unique identifier from users of
                      our Site, including children; we only use such identifiers
                      to support the internal operations of our Site and we do
                      not use such identifiers to collect information about the
                      child outside of our Site.
                    </p>
                  </div>

                  <div className="description">
                    <h2>5. How We Use Children’s Information</h2>
                    <p className="mb-2">
                      We use personal information collected from children for
                      the following purposes:
                    </p>
                    <p className="mb-2">To provide our Services; and</p>
                    <p className="mb-2">
                      To respond to customer service and technical support
                      issues and requests.
                    </p>
                    <p className="mb-2">
                      We de-identify and/or aggregate the information we collect
                      from children under 13 before we use it for any other
                      purposes, as noted below.
                    </p>
                    <p className="mb-2">
                      UNIQUE IDENTIFIERS: We only collect and use unique
                      identifiers, such as IP addresses, as necessary to operate
                      our Site or Services, including to maintain or analyze
                      their functioning; perform network communications;
                      authenticate users or personalize content; and protect the
                      security or integrity of users and our Site and Services.
                      We never use unique identifiers to track users across
                      third-party apps or websites.
                    </p>
                    <p className="mb-2">
                      AGGREGATE OR DE-IDENTIFIED INFORMATION: We may use
                      aggregate or de-identified information about children for
                      research, analysis, and similar purposes. When we do so,
                      we strip out names, e-mail, contact information, and other
                      personal identifiers. We may use aggregate or
                      de-identified information for the following purposes:
                    </p>
                    <ul>
                      <li>
                        <p>
                          To better understand how users access and use our Site
                          and Services;
                        </p>
                      </li>
                    </ul>

                    <ul>
                      <li>
                        <p>
                          To improve our Site and Services and respond to user
                          desires and preferences; and
                        </p>
                      </li>
                    </ul>

                    <ul>
                      <li>
                        <p>
                          To conduct research or analysis, including research
                          and analysis by third parties.
                        </p>
                      </li>
                    </ul>
                  </div>

                  <div className="description">
                    <h2>6. How We Share Children’s Information</h2>
                    <p className="mb-2">
                      We do not sell children’s personal information, and a
                      child may not make his or her personal information public
                      through our Services. In general, we may disclose the
                      personal information that we collect about children to
                      provide our Services, to comply with the law, and to
                      protect Ilmiya and other users of our Services. For
                      example, we may share children’s personal information as
                      follows:
                    </p>
                    <p className="mb-2">
                      SERVICE PROVIDERS: We may disclose the information we
                      collect from children to third-party vendors, service
                      providers, contractors, or agents who perform functions on
                      our behalf.
                    </p>
                    <p className="mb-2">
                      BUSINESS TRANSFERS: If we are acquired by or merged with
                      another company, if substantially all of our assets are
                      transferred to another company, or as part of a bankruptcy
                      proceeding, we may transfer the personal information we
                      have collected from our users to the other company.
                    </p>
                    <p className="mb-2">
                      IN RESPONSE TO LEGAL PROCESS: We also may disclose the
                      personal information we collect in order to comply with
                      the law, a judicial proceeding, court order, subpoena, or
                      other legal process.
                    </p>
                    <p className="mb-2">
                      TO PROTECT US AND OTHERS: We also may disclose the personal
                      information we collect where we believe it is necessary to
                      investigate, prevent, or take action regarding illegal
                      activities, suspected fraud, situations involving
                      potential threats to the safety of any person, violations
                      of our <a href="#">
                            {" "}
                            <span className="bg-blue" style={{color:'#0d3c82', fontWeight:'500'}}>
                              <FiAlertCircle />
                              Terms and Services
                            </span>
                          </a> , Privacy Statement,
                      or this Child Privacy Statement, or as evidence in
                      litigation in which ILMIYA is involved.{" "}
                    </p>
                    <p>
                      WITH PARENTS: Parents may request information about the
                      information we have collected from their child by
                      contacting us at: <a href="#"> <span style={{fontWeight:'500'}}>support@ilmiya.com </span>.</a>
                    </p>
                    <p className="mt-2">
                      AGGREGATE AND DE-IDENTIFIED INFORMATION: We may also use
                      and share aggregate or de-identified information about
                      users with third parties for marketing, research, or
                      similar purposes.
                    </p>
                  </div>

                  <div className="description">
                    <h2>
                      7. Your Rights to Review, Delete, and Control Our Use of
                      Children’s Personal Information
                    </h2>
                    <p>
                      Parents have a right to review the information we have
                      collected about their children and students, respectively,
                      and to delete it, and to tell us to stop using it. To
                      exercise these rights, you may contact us at :{" "}
                     <a href="#"> <span style={{ fontWeight:'500'}}>Suppport @ilmiya.com</span></a>. You will be required to
                      authenticate yourself as the child’s parent to receive
                      information about that child. Please note that copies of
                      information may remain in cached or archived form on our
                      systems after you request us to delete it.
                    </p>
                  </div>
                  <div className="description">
                    <h2>8. Changes to Child Privacy Statement</h2>
                    <p>
                      We may change this Child Privacy Statement from time to
                      time, so please be sure to check back periodically. We
                      will post any changes to this Child Privacy Policy on our
                      Site. If we make any changes to this Child Privacy
                      Statement that materially affect our practices with regard
                      to the personal information we have previously collected
                      from a child, we will endeavor to provide you with notice
                      in advance of such change by highlighting the change on
                      our Site.
                    </p>
                  </div>
                </div>
               </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SecondaryFooter />
    </>
  );
};

export default PrivacyDisclouser;
